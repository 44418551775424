import React, { useState, useContext } from "react";
import { useMediaQuery, Dialog, DialogContent, DialogActions, Typography, Link, Checkbox, FormControlLabel } from '@material-ui/core';
import StyledButton from "../common/StyledButton";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import UserContext from "../../UserContext";
import { updateTermsVersion } from "../../services/user_services";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import Iframe from 'react-iframe'

const useStyles = makeStyles((theme) => ({
  termsWrapper: {
    height: "400px",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      height: '55vh',
    }
  },
}));

const Tutorial = ({showModalTerms, setShowModalTerms, handleLogout}) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { country, setHasAcceptedLatestTerms } = useContext(UserContext);
  const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  }

  const acceptTerms = () => {
    logAction("Accepted terms and conditions", country, location.pathname); //Mixpanel logging
    setHasAcceptedLatestTerms(true);
    updateTermsVersion();
    setShowModalTerms(false);
  }

  return (
    <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"md"} open={showModalTerms}>
      <DialogContent>
        <div className={classes.termsWrapper}>
          <Iframe 
            url="https://www-learnfreely-app.filesusr.com/html/b5c998_0d178f68af7cf85db37e805b0d67b238.html"
            width="100%"
            height="100%"
          />
        </div>
        <Typography paragraph>
          Please ensure that you have read and agree with our Terms and Conditions. You can also view our <Link href="https://www.learnfreely.app/privacy">Privacy Policy</Link>.
        </Typography>
        <FormControlLabel
                control={
                  <Checkbox 
                    onChange={() => {handleCheckboxChange()}}
                    checked={isChecked}
                  />
                }
                label="I agree"
        />
      </DialogContent>
      <DialogActions>
          <StyledButton onClick={() => handleLogout()}>Logout</StyledButton>
          <StyledButton onClick={() => acceptTerms()} disabled={!isChecked}>Continue</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default Tutorial;