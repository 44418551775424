import React, { useState, Fragment, useContext } from "react";
import { deleteCategory } from "../../services/category_services";
import { Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Typography, IconButton } from "@material-ui/core";
import StyledButton from "../common/StyledButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import {updateCategory} from '../../services/category_services';
import CategoryModal from '../../components/categories/CategoryModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
    cursor: "pointer",
  }
}));

const Category = ({ data, categories, setCategories }) => {
  const classes = useStyles();
  const { country } = useContext(UserContext);
  const [name, setName] = useState(data.name);
  const [color, setColor] = useState(data.color);
  const [icon, setIcon] = useState(data.icon);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const location = useLocation();

  const submitUpdatedCategory = (event) => {
    event.preventDefault();
    const id = data._id;
    const submitData = {
        _id: id,
        name: name,
        color: color,
        icon: icon
    }
    updateCategory(submitData).then((response) => {
        logAction("Updated category", country, location.pathname, submitData); //Mixpanel logging
        let categoryIndex = categories.findIndex(category => category._id === id);
        let categoriesCopy = [...categories];
        categoriesCopy[categoryIndex].name = name;
        categoriesCopy[categoryIndex].color = color;
        categoriesCopy[categoryIndex].icon = icon;
        setCategories([...categoriesCopy]);
        document.getElementById("form").reset()

    }).catch(error =>{
        console.log("An error occured during student submission:", error);
    });
    setShowEditCategoryModal(false);
  }

  const hideModal = () => {
      setName(data.name);
      setColor(data.color);
      setIcon(data.icon);
      setShowEditCategoryModal(false);
  }

  const onDelete = (event) => {
    event.preventDefault();
    deleteCategory({ deleteId: data._id })
      .then((response) => {
        logAction("Deleted category", country, location.pathname, data); //Mixpanel logging
        setCategories(categories.filter((st) => st._id !== data._id));
      })
      .catch((error) => {
        console.log("An error occurred trying to delete ", data.name, ": ", error);
      });
    setShowDeleteConfirm(false);
  };

  const clickCategory = () => {
    setName(data.name);
    setColor(data.color);
    setIcon(data.icon);
    setShowEditCategoryModal(true);
  };

  const deleteConfirmModal = () => {
    return (
      <Dialog open={showDeleteConfirm}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this category?</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              setShowDeleteConfirm(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={onDelete}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Fragment>
      <ListItem
        className={classes.root}
        onClick={() => {clickCategory()}}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={data.icon} style={{ color: data.color }} size="lg" />
        </ListItemIcon>
        <ListItemText>
          {data.name}
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {deleteConfirmModal()}
      <CategoryModal isOpen={showEditCategoryModal} cancelFunction={hideModal} submitFunction={submitUpdatedCategory} name={name} setName={setName} color={color} setColor={setColor} icon={icon} setIcon={setIcon} />
    </Fragment>
  );
};

export default Category;