import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, useTheme, useMediaQuery, IconButton, SvgIcon } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StyledButton from '../common/StyledButton';
import IconModal from '../common/IconModal';
import StudentSelector from "../common/StudentSelector";
import CategorySelector from "../common/CategorySelector";
import { getProjectIcons } from "../../containers/projects/ProjectIcons";
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    fullWidthField: {
        display: "flex",
        width: "100%",
    },
    name: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
    },
    label: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[600],
    }
}));

const ProjectEditModal = ({ students, categories, isOpen, submitFunction, cancelFunction, name, setName, color, setColor, icon, setIcon, description, setDescription, dateCompleted, setDateCompleted, studentsToInclude, setStudentsToInclude, categoriesToInclude, setCategoriesToInclude, logoUrl }) => {

    const classes = useStyles();
    const theme = useTheme();
    const [showIconModal, setShowIconModal] = useState(false);
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const projectIcons = getProjectIcons();

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const onClickIcon = (clickedIcon) => {
        setIcon(clickedIcon);
        setShowIconModal(false);
    }

    return (
        <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"sm"} open={isOpen}>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <IconButton
                        onClick={cancelFunction}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    Project
                </div>
            </DialogTitle>
            <DialogContent>
                <form id="projectedit_form" onSubmit={submitFunction} autoComplete="off">
                    <div className={classes.fullWidthField}>
                        <TextField className={classes.name} name="name" label="Name" value={name} onChange={onNameChange} required/>
                    </div>
                    <div className={classes.fullWidthField}>
                        <TextField className={classes.name} name="description" label="Description" value={description} onChange={onDescriptionChange} multiline />
                    </div>
                    <StudentSelector students={students} studentsToInclude={studentsToInclude} setStudentsToInclude={setStudentsToInclude} />
                    <CategorySelector categories={categories} categoriesToInclude={categoriesToInclude} setCategoriesToInclude={setCategoriesToInclude} />
                    { logoUrl ? null :
                        <Fragment>
                            <InputLabel className={classes.label}>Icon</InputLabel>
                            <IconButton className={classes.iconButton} onClick={() => {setShowIconModal(true)}}>
                                <SvgIcon 
                                    component={projectIcons[icon]} 
                                    viewBox="0 0 128 128" 
                                />
                            </IconButton>
                        </Fragment>
                    }
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={cancelFunction}>
                    Cancel
                </StyledButton>
                <StyledButton form="projectedit_form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
            <IconModal 
                showIconModal={showIconModal} 
                setShowIconModal={setShowIconModal}
                iconList={projectIcons}
                onClickIcon={onClickIcon}
                iconType='SVG'
            />
        </Dialog>
    )
}

export default ProjectEditModal;