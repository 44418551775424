import React, { useState, useContext, useEffect } from "react";
import { ListItem, Tooltip, Avatar, Chip, SvgIcon, Checkbox } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import EditActivity from "../../containers/activities/EditActivity";
import ReorderMenu from "./ReorderMenu";
import { getProjectIcons } from "../../containers/projects/ProjectIcons";
import { deleteActivity, getActivityById } from "../../services/activity_services";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logAction, logActivityAction } from "../../log";
import UserContext from "../../UserContext";
import { useLocation } from "react-router-dom";
import { getThumbnails } from "../../containers/activities/GetAttachments";
import { deleteMultipleAttachments } from "../../services/attachment_services";
import ActivityContext from "../../containers/activities/ActivityContext";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ProjectPlanLogo from "../common/ProjectPlanLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row"
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
    alignItems: "flex-start",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  cardContainerComplete: {
    backgroundColor: theme.palette.complete.shade[50],
  },
  cardTop: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  cardTopLeft: {
    flexGrow: 1,
  },
  cardTopRightWrapper: {
    display: "flex",
    alignItems: "flex-start"
  },
  cardTopRight: {
    display: "flex",
    flexDirection: "column",
  },
  cardTopRightNarrow: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  cardMid: {
    display: "flex",
    width: "100%",
  },
  cardBottom: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  avatarNarrow: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: "1em",
  },
  avatarNarrowComplete: {
    borderColor: theme.palette.complete.shade[50],
  },
  project: {
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  categoryIcons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  categoryIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  htmlContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    lineHeight: 1.2,
    maxHeight: "4.8em", //4 lines high
    position: "relative",
    overflow: "hidden",
    "& p": {
      margin: 0,
    },
    "&::after": {
      content: '""', //empty string
      position: "absolute",
      top: "3em", //not visible if less than 4 lines
      bottom: 0,
      height: "1.8em",
      width: "100%",
      background: "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
    },
  },
  htmlContentNarrow: {
    fontSize: "0.9em",
    maxHeight: "2.4em", //2 lines high
    "&::after": {
      top: "1.2em", //not visible if less than 2 lines
      height: "1.3em",
    },
  },
  htmlContentNarrowComplete: {
    "&::after": {
      background: "linear-gradient(rgba(226, 244, 235, 0), rgba(226, 244, 235, 1))",
    },
  },
  thumbnails: {
    display: "flex",
  },
  thumbnail: {
    marginRight: "10px",
  },
  date: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    color: theme.palette.grey[800],
  },
  projectChip: {
    backgroundColor: theme.palette.secondary.shade[75],
    color: theme.palette.text.primary,
    fontStyle: "italic",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  attachmentIcon: {
    color: theme.palette.grey[800],
  },
  completedCheck: {
    color: theme.palette.complete.main,
  },
  projectLogo: {
    height: "24px",
    width: "24px"
  }
}));

const Activity = ({ 
  students, 
  categories, 
  projects, 
  data, 
  activities, 
  setActivities, 
  filterText,
  selectedStudent,
  groupActivities, 
  activityGroupIndex, 
  setNotificationDetails, 
  narrow = false, 
  showCheckbox = false, 
  checkedActivities = [], 
  handleCheckboxChange = null, 
  hideReorderMenu = false 
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { country } = useContext(UserContext);
  const [showEditActivityModal, setShowEditActivityModal] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const location = useLocation();
  const projectIcons = getProjectIcons();
  const [activityId, setActivityId] = useState(data._id);
  const [details, setDetails] = useState(data.textContent);
  const [studentsToInclude, setStudentsToInclude] = useState(data.students);
  const [categoriesToInclude, setCategoriesToInclude] = useState(data.categories);
  const [projectToInclude, setProjectToInclude] = useState(data.project);
  const [activityDate, setActivityDate] = useState(data.date);
  const [activityCompleteDate, setActivityCompleteDate] = useState(data.dateCompleted);
  const [attachments, setAttachments] = useState(data.attachments);
  const [attachmentLinks, setAttachmentLinks] = useState(data.attachmentLinks);
  const [comments, setComments] = useState(data.comments);

  const activityState = {
    activityId, 
    setActivityId,
    details,
    setDetails,
    studentsToInclude,
    setStudentsToInclude,
    categoriesToInclude,
    setCategoriesToInclude,
    projectToInclude,
    setProjectToInclude,
    activityDate,
    setActivityDate,
    activityCompleteDate,
    setActivityCompleteDate,
    attachments,
    setAttachments,
    attachmentLinks,
    setAttachmentLinks,
    comments,
    setComments
  };

  useEffect(() => {
    getThumbnails(data.attachments, setThumbnails);
  }, [data.attachments]);

  function renderActivityDate(date, completed = false) {
    const d = DateTime.fromISO(date);
    const locale = country === "US" ? "en-us" : "en-au";
    const formattedDate = d.toLocaleString({ month: "short", day: "2-digit", locale: locale });
    if (completed) {
      return <span>Completed {formattedDate}</span>;
    } else {
      return <span>{formattedDate}</span>;
    }
  }

  const displayStudents = () => {
    let activityStudents = data.students.map((student_id, i) => { //Get student props
      const studentRecord = students.find((student) => student._id === student_id)
      if (studentRecord) {
        return studentRecord;
      } else {
        return false;
      }
    })
    activityStudents = activityStudents.sort((a, b) => { //Sort by _id
      if(a._id < b._id) { return -1; }
      if(a._id > b._id) { return 1; }
      return 0;
    })
    return (
      <AvatarGroup>
        {activityStudents.map((student, i) => {
          if (student) {
            const studentInitial = student.name.substring(0, 1).toUpperCase();
            return (
              <Tooltip key={i} title={student.name}>
                <Avatar 
                  style={{ backgroundColor: `${student.color}` }}
                  className={`${narrow && classes.avatarNarrow} ${narrow && data.dateCompleted && classes.avatarNarrowComplete}`}
                >
                  {studentInitial}
                </Avatar>
              </Tooltip>
            );
          } else return false;
        })}
      </AvatarGroup>
    );
  };

  const displayCategories = () => {
    let activityCategories = data.categories.map((category_id, i) => { //Get category props
      const categoryRecord = categories.find((category) => category._id === category_id)
      if (categoryRecord) {
        return categoryRecord;
      } else {
        return false;
      }
    })
    activityCategories = activityCategories.sort((a, b) => { //Sort by name
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
    return (
      <div className={classes.categoryIcons}>
        {activityCategories.map((category, i) => {
          if (category) {
            return (
              <Tooltip key={category._id} title={category.name}>
                <Avatar variant="square" className={classes.categoryIcon} style={{ background: "none", color: `${category.color}` }}>
                  <FontAwesomeIcon icon={category.icon} style={{width: theme.spacing(3)}} />
                </Avatar>
              </Tooltip>
            );
          } else return false;
        })}
      </div>
    );
  };

  const displayProject = () => {
    const projectRecord = projects.find((project) => project._id === data.project)
    if (projectRecord) {
      return (
        <div className={classes.project}>
          {
            !narrow
            ? <Chip 
              className={classes.projectChip} 
              avatar={projectRecord.logoUrl 
                ? <ProjectPlanLogo name={projectRecord.name} logoUrl={projectRecord.logoUrl} className={classes.projectLogo} />
                : null
              }
              icon={!projectRecord.logoUrl 
                ? <SvgIcon component={projectIcons[projectRecord.icon]} viewBox="0 0 128 128" /> 
                : null
              }
              label={projectRecord.name} 
            />
            : projectRecord.logoUrl
            ? <ProjectPlanLogo name={projectRecord.name} logoUrl={projectRecord.logoUrl} className={classes.projectLogo} />
            : <SvgIcon component={projectIcons[projectRecord.icon]} viewBox="0 0 128 128" />
          }
        </div>
      )
    } else return false;
  };

  const displayThumbnails = () => {
    if (!narrow) {
      return (
        <div className={classes.thumbnails}>
          {thumbnails.map((thumbnail, i) => {
            if (thumbnail === undefined) {
              return (
                <Tooltip key={i} title={"Attachment"}>
                  <Avatar variant="square" className={classes.thumbnail}>
                    {<FontAwesomeIcon icon="paperclip" />}
                  </Avatar>
                </Tooltip>
              );
            }
            return (
              <Tooltip key={i} title={thumbnail.userFileName || ""}>
                <Avatar src={thumbnail.signedUrl} alt={thumbnail.userFileName} variant="rounded" className={classes.thumbnail}>
                  {thumbnail.fileType === "application/pdf" ? <FontAwesomeIcon icon="file-pdf" /> : <FontAwesomeIcon icon="paperclip" />}
                </Avatar>
              </Tooltip>
            );
          })}
        </div>
      );
    } else {
      if (thumbnails.length) {
        return <AttachFileIcon fontSize="small" className={classes.attachmentIcon} />
      }
    };
  };

  const displayDate = () => {
    let returnData = "";
    if (!narrow) {
      if (data.dateCompleted) {
        returnData = renderActivityDate(data.dateCompleted, true);
      } else if (data.date) {
        returnData = renderActivityDate(data.date);
      }
    } else {
      if (data.dateCompleted) {
        returnData = <CheckCircleIcon className={classes.completedCheck} />
      }
    }
    return (
      <div className={classes.date}>
        {returnData}
      </div>
    )
  };

  const removeActivity = () => {
    if (data.attachments.length) { //If there are attachments, delete them
      deleteMultipleAttachments(data.attachments.map((attachment) => {return attachment.s3FileName}))
      .catch((error) => {
        console.log("Error:", error);
      });
    }

    deleteActivity({ deleteId: data._id })
      .then(() => {
        logActivityAction("Deleted activity", country, location.pathname, data); //Mixpanel logging
        setActivities(activities.filter((activity) => activity._id !== data._id));
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const clickActivity = () => {
    logActivityAction("Opened activity", country, location.pathname, data); //Mixpanel logging
    getActivityById(data._id)
      .then((response) => {
        if (response.data !== null) {
          data = response.data;
          setDetails(data.textContent);
          setStudentsToInclude(data.students);
          setCategoriesToInclude(data.categories);
          setProjectToInclude(data.project);
          setActivityDate(data.date);
          setActivityCompleteDate(data.dateCompleted);
          setAttachments(data.attachments);
          setAttachmentLinks(data.attachmentLinks);
          setComments(data.comments);
        }
        setShowEditActivityModal(true);
      })
      .catch((error) => {
        console.log(error);
        logAction("Failed to retrieve activity", country, location.pathname, error); //Mixpanel logging
      })
  };

  function createMarkup() {
    return { __html: data.textContent };
  }

  return (
    <div className={classes.root}>
      {showCheckbox && 
        <div>
          <Checkbox 
            onChange={() => {handleCheckboxChange(data._id)}}
            checked={checkedActivities.includes(data._id)}
          />
        </div>
      }
      <ListItem className={`${classes.cardContainer} ${narrow && data.dateCompleted && classes.cardContainerComplete}`} disableGutters={!narrow ? false : true} onClick={clickActivity}>
        <div className={classes.cardTop}>
          <div className={classes.cardTopLeft}>
            {displayStudents()}
          </div>
          <div className={classes.cardTopRightWrapper}>
            <div className={!narrow ? classes.cardTopRight : classes.cardTopRightNarrow}>
                {displayProject()}
                {displayCategories()}
            </div>
            {
              !hideReorderMenu && 
              <ReorderMenu 
                activities={activities}
                setActivities={setActivities}
                filterText={filterText}
                selectedStudent={selectedStudent}
                groupActivities={groupActivities}
                activityGroupIndex={activityGroupIndex}
              />
            }
          </div>
        </div>
        <div className={classes.cardMid}>
          <div className={`${classes.htmlContent} ${narrow && classes.htmlContentNarrow} ${narrow && data.dateCompleted && classes.htmlContentNarrowComplete}`} dangerouslySetInnerHTML={createMarkup()} />
        </div>
        <div className={classes.cardBottom}>
          {displayThumbnails()}
          {displayDate()}
        </div>
      </ListItem>
      <ActivityContext.Provider value={activityState}>
        <EditActivity activities={activities} isOpen={showEditActivityModal} setShowActivityModal={setShowEditActivityModal} students={students} categories={categories} projects={projects} setActivities={setActivities} deleteFunction={removeActivity} setNotificationDetails={setNotificationDetails} />
      </ActivityContext.Provider>
    </div>
  );
};

export default Activity;