import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from "../../UserContext";
import ActivitiesList from './ActivitiesList';
import StyledButton from "../common/StyledButton";
import SearchBar from './SearchBar';
import { updateActivities } from "../../services/activity_services";
import { logAction } from "../../log";
import { getFilteredData } from '../../containers/activities/FilterData';
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";

const useStyles = makeStyles(theme => ({
    search: {
        paddingBottom: theme.spacing(2)
    },
}));

const FindActivitiesModal = ({activities, setActivities, students, categories, projects, checkedActivities, setCheckedActivities, setNotificationDetails, open, closeFunction, studentId, categoryId, date}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const { country } = useContext(UserContext);
    const location = useLocation();
    const [filterText, setFilterText] = useState("");
    const locale = country === "US" ? "en-us" : "en-au";
    const dayName = date.toLocaleString({ weekday: "long", locale: locale });

    const categoryRecord = categories.find((c) => c._id === categoryId);
    const studentRecord = students.find((student) => student._id === studentId);

    const filters = useMemo(() => {
        return [
            getFilteredData(activities, {
                student: studentId,
                completed: 0, 
                date: {
                    mode: 2
                },
                category: categoryRecord
            }),
        ]
    }, [activities, categoryRecord, studentId]);

    useEffect(() => { //deselect activity if it is updated and no longer shown in filter
        let c = checkedActivities;
        checkedActivities.map((checkedActivity) => {
            let checkedActivityPresent = false;
            filters[0].map((activity) => {
                if (checkedActivity === activity._id) {
                    checkedActivityPresent = true;
                }
                return true;
            });
            if (!checkedActivityPresent) {
                const index = c.indexOf(checkedActivity);
                c.splice(index, 1);
                setCheckedActivities([...c]);
            }
            return true;
        })
    }, [activities, filters, checkedActivities, setCheckedActivities]);

    const handleCheckboxChange = (id) => {
        let c = checkedActivities;
        const index = checkedActivities.indexOf(id);
        if (index >= 0) {
          c.splice(index, 1);
        } else {
          c.push(id);
        }
        setCheckedActivities([...c]);
    };

    const scheduleActivities = () => {
        const activityDate = date.toUTC().toISO()
        const activityArray = checkedActivities.map((checkedActivity) => {
            let checkedActivityRecord = activities.filter((activity) => activity._id === checkedActivity);
            checkedActivityRecord = checkedActivityRecord[0];

            const submitRecord = {
                _id: checkedActivityRecord._id,
                textContent: checkedActivityRecord.textContent,
                date: activityDate,
                dateCompleted: checkedActivityRecord.dateCompleted,
                students: checkedActivityRecord.students,
                categories: checkedActivityRecord.categories,
                project: checkedActivityRecord.project,
                attachments: checkedActivityRecord.attachments,
                attachmentLinks: checkedActivityRecord.attachmentLinks,
            };

            return submitRecord;
        });

        updateActivities({activityArray})
        .then((response) => {
            logAction("Scheduled activity(s) from MyWeek", country, location.pathname, { //Mixpanel logging
                count: activityArray.length, 
                activities: activityArray.map((activity) => { return activity._id})
            });
            let activityCopy = [...activities];
            activityArray.map((activity) => {
                let activityIndex = activities.findIndex((a) => a._id === activity._id);
                activityCopy[activityIndex].date = activityDate;
                return true;
            })
            setActivities([...activityCopy]);
            let message = "";
            const formattedDate = date.toLocaleString({ weekday: "short", month: "short", day: "numeric", locale: locale });
            const today = DateTime.local().startOf('day');
            const difference = date.diff(today, "days").values.days;
            switch (difference) {
            case 0:
                message = activityArray.length === 1 ? "Activity is now scheduled for today" : `${activityArray.length} activities are now scheduled for today`;
                break;
            case 1: 
                message = activityArray.length === 1 ? "Activity is now scheduled for tomorrow" : `${activityArray.length} activities are now scheduled for tomorrow`;
                break;
            default:
                message = activityArray.length === 1 ? `Activity is now scheduled for ${formattedDate}`: `${activityArray.length} activities are now scheduled for ${formattedDate}`;
            }
            setNotificationDetails({
              key: Date.now(),
              icon: "calendar",
              title: "Date changed",
              message: message
            });
            closeFunction();
        })
        .catch((error) => {
            logAction("Activity save error on update.", country, location.pathname, error); //Mixpanel logging
            return
        });
    }

    return (
            categoryRecord && studentRecord ?
            <Dialog open={open} onClose={closeFunction} fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"md"}>
                <DialogTitle>
                    {categoryRecord.name} for {studentRecord.name}
                </DialogTitle>
                <DialogContent>
                    <div className={classes.search}>
                        <SearchBar filterText={filterText} setFilterText={setFilterText} />
                    </div>
                    <ActivitiesList 
                        activities={activities} 
                        setActivities={setActivities} 
                        students={students} 
                        categories={categories} 
                        projects={projects}
                        filterIndex={0}
                        filters={filters}
                        filterText={filterText}
                        setNotificationDetails={setNotificationDetails}
                        showCheckbox={true}
                        checkedActivities={checkedActivities}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </DialogContent>
                <DialogActions>
                    <StyledButton 
                        onClick={closeFunction}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton
                        onClick={scheduleActivities}
                        disabled={!checkedActivities.length}
                    >
                        Schedule {checkedActivities.length > 0 && checkedActivities.length} for {dayName}
                    </StyledButton>
                </DialogActions>
            </Dialog>
            : null
    )
};

export default FindActivitiesModal;