import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: "400px"
  },
  categoryIcon: {
    minWidth: theme.spacing(4),
  },
  categoryName: {
    paddingRight: theme.spacing(2)
  },
  checkNumber: {
    fontSize: "0.6em",
    fontWeight: "bold"
  }
}));

const CategorySelectorList = ({ categories, categoriesToInclude, setCategoriesToInclude }) => {

    const classes = useStyles();

    const isChecked = (id) => {
      const index = categoriesToInclude.findIndex((c) => {
        return c === id
      })
      if (index >= 0) {
        return true
      } else {
        return false
      }
    };

    const getIndex = (id) => {
      const index = categoriesToInclude.findIndex((c) => {
        return c === id
      })
      return index + 1;
    }

    const handleToggle = (id) => () => {
      let categoriesToIncludeCopy = [...categoriesToInclude]
      const index = categoriesToInclude.findIndex((c) => {
        return c === id
      })
      if (index >= 0) {
        categoriesToIncludeCopy.splice(index, 1)
      } else {
        categoriesToIncludeCopy.push(id)
      }
      setCategoriesToInclude(categoriesToIncludeCopy)
    };

    return (
      <List className={classes.root}>
        {categories.map((category, index) => {
            return (
              <ListItem key={index} button onClick={handleToggle(category._id)}>
                <ListItemIcon className={classes.categoryIcon}>
                  <FontAwesomeIcon icon={category.icon} style={{ color: category.color }} size="lg" /> 
                </ListItemIcon>
                <ListItemText className={classes.categoryName}>
                  {category.name}
                </ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                      edge="end"
                      checked={isChecked(category._id)}
                      onClick={handleToggle(category._id)}
                      icon={
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={["far", "square"]} />
                        </span>
                      }
                      checkedIcon={
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon="square" />
                          <span className={`fa-layers-text fa-inverse ${classes.checkNumber}`} transform="shrink-8">{getIndex(category._id)}</span>
                        </span>
                      }
                      disableRipple
                    />
                </ListItemSecondaryAction>
              </ListItem>
            )
        })}
      </List>
    );
}

export default CategorySelectorList;