import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, useMediaQuery } from '@material-ui/core';
import StyledButton from '../common/StyledButton';
import { DateTime } from "luxon";

const useStyles = makeStyles(theme => ({
    nameLabel: {
        width: "100%",
    },
    statusField: {
        width: "100%",
    }
}));

const GoalModal = ({showGoalModal, submitFunction, name, setName, status, setStatus, dateCompleted, setDateCompleted}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down('xs'));

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onStatusChange = (event) => {
        const value = event.target.value;
        setStatus(value);
        if (value === "Complete") {
            setDateCompleted(DateTime.local().toUTC().toString())
        } else {
            setDateCompleted("");
        }
    }

    return (
        <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"md"}  open={showGoalModal}>
            <DialogTitle>Goal</DialogTitle>
            <DialogContent>
                <form id="form" onSubmit={(e) => submitFunction(e)} autoComplete="off">
                    <TextField name="name" label="Name" className={classes.nameLabel} value={name} onChange={onNameChange} required/>
                    <FormControl className={classes.statusField}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            name={'status'}
                            value={status}
                            onChange={onStatusChange}
                        >
                            <MenuItem value={""}></MenuItem>
                            <MenuItem value={"Complete"}>Complete</MenuItem>
                        </Select>
                        </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton form="form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}

export default GoalModal;