import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { IconButton, SvgIcon, useMediaQuery } from '@material-ui/core';
import SimpleDialog from "./SimpleDialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
    iconGrid: {
        display: "flex",
        flexWrap: "wrap",
    },
    iconGridItem: {
        margin: theme.spacing(1),
    }
}));

const IconModal = ({showIconModal, setShowIconModal, iconList, onClickIcon, iconType}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));

    const icons = () => {
        if (iconType === 'FontAwesome') {
            return iconList.map((iconListItem, i) => {
                return (
                    <IconButton
                        key={i}
                        className={classes.iconGridItem}
                        onClick={() => {onClickIcon(iconListItem)}}
                    >
                        <FontAwesomeIcon 
                            icon={iconListItem}
                            style={{width: theme.spacing(3)}}
                        />
                    </IconButton>
                )
            });
        } else if (iconType === 'SVG') {
            let arr = []
            for (let [iconListItemKey, iconListItem] of Object.entries(iconList)) {
                arr.push(
                    <IconButton
                        key={iconListItemKey}
                        className={classes.iconGridItem}
                        onClick={() => {onClickIcon(iconListItemKey)}}
                    >
                        <SvgIcon 
                            component={iconListItem} 
                            viewBox="0 0 128 128" 
                        />
                    </IconButton>
                )
            }
            return arr;
        }
    }

    return (
        <SimpleDialog 
            open={showIconModal}
            fullScreen={screenIsXs ? true : false}
            onClose={() => {setShowIconModal(false)}}
            title="Select an icon"
            buttonPrimary={{
                onClick: () => {setShowIconModal(false)},
                label: "Cancel"
            }}
        >
            <div className={classes.iconGrid}>
                {icons()}
            </div>
        </SimpleDialog>
    )
}

export default IconModal;