import React, { Fragment, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, IconButton, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { useGlobalState } from "../../config/store";
import UserContext from "../../UserContext";
import FlaggedGoals from "../goals/FlaggedGoals";
import Banner from "../notifications/Banner";
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import logo1x from "../../assets/images/logo1x.png";
import logo2x from "../../assets/images/logo2x.png";
import logo3x from "../../assets/images/logo3x.png";
import logo4x from "../../assets/images/logo4x.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  titleImg: {
    height: "26px",
  },
}));

const Header = ({ navOpen, setNavOpen, students, goals, setGoals, setBannerClearance }) => {
  const classes = useStyles();
  const location = useLocation();
  const { pixelRatio, setPixelRatio } = useContext(UserContext);
  let logo = logo1x;

  const { isReady } = useContext(SplitContext);
  const treatmentName = {
    goals: "goals_functionality"
  };
  const treatment = useTreatments([treatmentName.goals]);

  const { store } = useGlobalState();
  const { loggedInUser } = store;

  const pixelRatioCheck = [useMediaQuery("(-webkit-min-device-pixel-ratio: 1)"), useMediaQuery("(-webkit-min-device-pixel-ratio: 2)"), useMediaQuery("(-webkit-min-device-pixel-ratio: 3)"), useMediaQuery("(-webkit-min-device-pixel-ratio: 4)")];

  let maxPixelRatio = 0;
  for (const p of pixelRatioCheck) {
    if (p) {
      maxPixelRatio++;
    }
  }

  switch (pixelRatio) {
    case 2:
      logo = logo2x;
      break;
    case 3:
      logo = logo3x;
      break;
    case 4:
      logo = logo4x;
      break;
    default:
      logo = logo1x;
  }

  useEffect(() => {
    setPixelRatio(maxPixelRatio);

    if (location.pathname === "/landing") {
      setNavOpen(false);
    }
  });

  function toggleNav() {
    navOpen ? setNavOpen(false) : setNavOpen(true);
  }

  const brandLogo = () => {
    return (
      <div className={classes.title}>
        <img className={classes.titleImg} src={logo} alt="Freely" />
      </div>
    );
  };

  function loggedIn() {
    return (
      <Fragment>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={() => toggleNav()}>
            <MenuIcon />
          </IconButton>
          {brandLogo()}
          {/* FEATURE FLAG */}
          {isReady && treatment[treatmentName.goals].treatment === "on" && <FlaggedGoals students={students} goals={goals} setGoals={setGoals} />}
        </Toolbar>
        <Banner setBannerClearance={setBannerClearance} />
      </Fragment>
    );
  }

  function loggedOut() {
    return (
      <Fragment>
        <Toolbar>{brandLogo()}</Toolbar>
      </Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {loggedInUser ? loggedIn() : loggedOut()}
      </AppBar>
      <Toolbar /> {/*This is for needed for spacing */}
    </div>
  );
};

export default Header;