import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProjectPlanAuthorLogo from '../../common/ProjectPlanAuthorLogo';

const useStyles = makeStyles(theme => ({
    tabs: {
        height: "calc(90vh - 120px)",
        maxHeight: "calc(90vh - 120px)",
        minWidth: 200,
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            maxHeight: "none",
            width: '100%',
        },
    },
    tab: {
        textAlign: "left",
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.secondary.shade[50],
        },
    },
    selectedTab: {
        backgroundColor: theme.palette.secondary.shade[50],
        '&:hover': {
            backgroundColor: theme.palette.secondary.shade[100],
        },
    },
    authorLogo: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    filterLabel: {
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    drawerHeader: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const ProjectSelectionFilter = ({projectPlans, activeTab, setActiveTab, setFilterAuthor, drawerOpen, setDrawerOpen, drawerStyle = false}) => {

        const classes = useStyles();

        const handleTabChange = (event, newValue) => {
            setActiveTab(newValue);
            setFilterAuthor(newValue);
        };

        const authors = projectPlans.map((projectPlan) => projectPlan.author)
        let uniqueAuthors = new Set(authors); //Remove duplicates
        uniqueAuthors = [...uniqueAuthors]; //Convert back to array

        const filterList = uniqueAuthors.map((uniqueAuthor) => {
            const authorObj = projectPlans.filter((projectPlan) => projectPlan.author === uniqueAuthor)
            return { //Only return the first filter result
                label: authorObj[0].author,
                logo: authorObj[0].authorLogoUrl
            }
        })
        filterList.unshift({
            label: "All",
            logo: ""
        })

        const filterTabs = () => {
            return (
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    orientation="vertical"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabs}
                >
                    {filterList.map((filterListItem, i) => {
                        return (
                            <Tab 
                                key={i} 
                                className={classes.tab}
                                classes={{ 
                                    selected: classes.selectedTab 
                                }}
                                value={filterListItem.label}
                                label={
                                    <div className={classes.filterLabel}>
                                        {filterListItem.logo && <ProjectPlanAuthorLogo name={filterListItem.label} logoUrl={filterListItem.logo} className={classes.authorLogo} />}
                                        {filterListItem.label}
                                    </div>
                                }
                            />
                        )
                    })}
                </Tabs>
            )
        }

        const drawerMenu = () => {
            return (
                <Drawer open={drawerOpen} anchor={"right"} onClick={() => {setDrawerOpen(false)}}>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={() => {setDrawerOpen(false)}}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {filterTabs()}
                </Drawer>
            )
        };

        return (
            drawerStyle
            ? drawerMenu()
            : filterTabs()
        )
}

export default ProjectSelectionFilter;