import React, {useState} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Avatar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import StyledButton from "../common/StyledButton";
import Alert from '@material-ui/lab/Alert';
import NewRoutineItem from './NewRoutineItem';
import EditRoutineItem from './EditRoutineItem';

const Routine = ({showRoutine, setShowRoutine, students, categories, routineItems, setRoutineItems}) => {

    const [selectedStudent, setSelectedStudent] = useState("");
    const [selectedDay, setSelectedDay] = useState(null);
    const [categoriesToInclude, setCategoriesToInclude] = useState([]);

    const days = [
        "MON",
        "TUE",
        "WED",
        "THU",
        "FRI",
        "SAT",
        "SUN"
    ]

    const hideModal = () => {
        setShowRoutine(false);
    }

    return (
        <Dialog open={showRoutine} onClose={hideModal} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>
                Routine
            </DialogTitle>
            <DialogContent>
                {
                    students.length
                    ? <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {days.map((day, i) => {
                                        return <TableCell key={i}>{day}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {students.map((student, i) => {
                                        const studentInitial = student.name.substring(0, 1).toUpperCase();
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <Tooltip title={student.name}>
                                                        <Avatar 
                                                            style={{ backgroundColor: `${student.color}` }}
                                                        >
                                                            {studentInitial}
                                                        </Avatar>
                                                    </Tooltip>
                                                </TableCell>
                                                {days.map((day, dayIndex) => {
                                                    const matchingRoutineItems = routineItems.filter((routineItem) => routineItem.student === student._id && routineItem.day === dayIndex);
                                                    const filteredMatchingRoutineItems = matchingRoutineItems.filter((routineItem) => {
                                                        if (routineItem.student === student._id && routineItem.day === dayIndex) {
                                                            const activeCategories = routineItem.categories.filter((category) => { //filter out deleted categories
                                                                const matchingCategory = categories.findIndex((c) => c._id === category)
                                                                if (matchingCategory >= 0) {
                                                                    return category;
                                                                } else {
                                                                    return null;
                                                                }
                                                            });
                                                            if (activeCategories.length) {
                                                                routineItem.categories = activeCategories;
                                                                return routineItem;
                                                            } else {
                                                                return null;
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    });
                                                    return (
                                                        matchingRoutineItems.length
                                                        ? <EditRoutineItem 
                                                            key={student._id + dayIndex} 
                                                            studentId={student._id} 
                                                            dayIndex={dayIndex} 
                                                            routineItems={routineItems}
                                                            matchingRoutineItems={matchingRoutineItems} //this is unfiltered in order to know if theres an existing routine item with deleted categories
                                                            filteredMatchingRoutineItems={filteredMatchingRoutineItems} //this is filtered to hide deleted categories in the ui
                                                            setRoutineItems={setRoutineItems}
                                                            selectedStudent={selectedStudent}
                                                            setSelectedStudent={setSelectedStudent}
                                                            selectedDay={selectedDay}
                                                            setSelectedDay={setSelectedDay}
                                                            categoriesToInclude={categoriesToInclude}
                                                            setCategoriesToInclude={setCategoriesToInclude}
                                                            students={students}
                                                            categories={categories}
                                                        />
                                                        : <NewRoutineItem 
                                                            key={student._id + dayIndex} 
                                                            studentId={student._id} 
                                                            dayIndex={dayIndex} 
                                                            routineItems={routineItems}
                                                            setRoutineItems={setRoutineItems}
                                                            selectedStudent={selectedStudent}
                                                            setSelectedStudent={setSelectedStudent}
                                                            selectedDay={selectedDay}
                                                            setSelectedDay={setSelectedDay}
                                                            categoriesToInclude={categoriesToInclude}
                                                            setCategoriesToInclude={setCategoriesToInclude}
                                                            students={students}
                                                            categories={categories}
                                                        />
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <Alert severity="info">Add a student</Alert>
                }
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={() => {hideModal()}}>
                    Done
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}

export default Routine;