import api from "../config/api";

export const getProjects = async () => {
  try {
    return await api.get("projects/getProjects");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const addProject = async (data) => {
  try {
    return await api.post("projects/newProject", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateProject = async (data) => {
  try {
    return await api.post("projects/updateProject", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const deleteProject = async (data) => {
  try {
    return await api.delete("projects/deleteProject", { data: data });
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const duplicateProjectActivities = async (data) => {
  try {
    return await api.post("projects/duplicateProjectActivities", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateProjectsFromProjectPlans = async (data) => {
  try {
    return await api.post("projects/updateProjectsFromProjectPlans", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};