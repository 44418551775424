import React, { useContext, useState, Fragment } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, IconButton, useMediaQuery, Tooltip } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import HelpIcon from '@material-ui/icons/Help';
import StyledButton from "../common/StyledButton";
import Spinner from "../common/Spinner";
import UserContext from "../../UserContext";
import ActivityContext from "../../containers/activities/ActivityContext";
import { wordCount } from '../../utils/WordCount';
import { submitNewActivities } from "../../services/activity_services";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../assets/css/react_day_picker_overrides.css';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  titleText: {
    flexGrow: 1,
  },
  toggleGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  fullWidthField: {
    width: "100%",
  },
  day: {
    height: "40px",
    width: "40px",
    paddingTop: "3px"
  },
  activityIndicatorWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  activityIndicator: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    background: theme.palette.grey[700],
    display: "block"
  },
}));

const DuplicateModal = ({activities, setActivities, students, showDuplicate, setShowDuplicate, setNotificationDetails}) => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const { country } = useContext(UserContext);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [tabValue, setTabValue] = useState("scheduled");
    const [noActivities, setNoActivities] = useState(1);
    const [duplicating, setDuplicating] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const locale = country === "US" ? "en-us" : "en-au";

    const { details, studentsToInclude, categoriesToInclude, projectToInclude, attachments, attachmentLinks } = useContext(ActivityContext);

    const template = {
      wordCount: wordCount(details),
      students: studentsToInclude,
      categories: categoriesToInclude,
      attachmentCount: attachments.length,
      attachmentLinkCount: attachmentLinks.length,
      project: projectToInclude,
    };

    const hideModal = (notificationData = {}) => {
      setShowDuplicate(false);
      setShowConfirm(false);
      setTabValue("scheduled");
      setNoActivities(1);
      setSelectedDays([]);
      let activityText = notificationData.count === 1 ? "activity" : "activities";
      if (notificationData.show) {
        setNotificationDetails({
          key: Date.now(),
          icon: "add",
          title: "Activity duplicated",
          message: `${notificationData.count} duplicate ${activityText} added`
        });
      };
    };

    const handleTabChange = (event, newValue) => {
      if (newValue) {
        setTabValue(newValue);
      }
    };

    const handleDayClick = (day) => {
      let s = selectedDays;
      const selectedIndex = s.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      if (selectedIndex >= 0) {
        s.splice(selectedIndex, 1);
      } else {
        s.push(day);
      }
      setSelectedDays([...s]);
    };

    const onNoActivitiesChange = (event) => {
      const number = event.target.value;
      if (number > 20) {
        setNoActivities(20);
      } else {
        setNoActivities(number);
      }
    };

    const handleSubmit = (activityArray) => {
      setDuplicating(true);
      submitActivities(activityArray);
    };

    const submitActivities = (activityArray) => {
      submitNewActivities({activityArray})
        .then((response) => {
          const newActivities = response;
          logAction("Created duplicate activities", country, location.pathname, { //Mixpanel logging
            count: newActivities.length, 
            template: template,
            activities: newActivities.map((activity) => { return activity._id})
          }); 
          setActivities([...activities, ...newActivities]); //Update local state in order to continue editing the activity
          setDuplicating(false);
          const notificationData = {
            show: true, 
            count: newActivities.length, 
          }
          hideModal(notificationData);
        })
        .catch((error) => {
          console.log("An error occurred during submission:", error);
        });
    };

    const prepareData = () => {
      let activityArray = [];
      if (tabValue === "scheduled") {
        activityArray = selectedDays.map((date) => {
          const d = DateTime.fromJSDate(date);
          const activityDate = d.startOf('day').toUTC().toString();
          return {
            textContent: details,
            date: activityDate,
            dateCreated: DateTime.local().toUTC().toString(),
            dateCompleted: null,
            students: studentsToInclude,
            categories: categoriesToInclude,
            project: projectToInclude,
            attachments: attachments,
            attachmentLinks: attachmentLinks,
          };
        });
      } else if (tabValue === "unscheduled") {
        let i = 0;
        while (i < noActivities) {
          activityArray.push({
            textContent: details,
            date: null,
            dateCreated: DateTime.local().toUTC().toString(),
            dateCompleted: null,
            students: studentsToInclude,
            categories: categoriesToInclude,
            project: projectToInclude,
            attachments: attachments,
            attachmentLinks: attachmentLinks,
          });
          i++;
        };
      };
      return activityArray;
    };

    const confirmModal = () => {
      let activityArray = [];
      if (showConfirm) {
        activityArray = prepareData();
      };

      const dialogContent = () => {
        let activityText = activityArray.length === 1 ? "activity" : "activities";
        let dateText = activityArray.length === 1 ? "date" : "dates";
        if (tabValue === "scheduled") {
          return (
            <Fragment>
              <Typography>
                {"Are you sure you want to create "}
                <strong>{activityArray.length + " " + activityText}</strong>
                {" for the following " + dateText + "?"}
              </Typography>
              <ul>
                {selectedDays.map((date, i) => {
                  const d = DateTime.fromJSDate(date)
                  const formattedDate = d.toLocaleString({ weekday: "long", month: "long", day: "2-digit", locale: locale });
                  return (
                    <li key={i}>{formattedDate}</li>
                  )
                })}
              </ul>
            </Fragment>
          )
        } else if (tabValue === "unscheduled") {
          return (
            <Typography>
              {"Are you sure you want to create "}
              <strong>{activityArray.length + " " + activityText}</strong>
              {"?"}
            </Typography>
          )
        };
      };

      return (
        <Dialog open={showConfirm}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            {dialogContent()}
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={() => {
                setShowConfirm(false);
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton 
              onClick={() => {
                handleSubmit(activityArray)
              }}
            >
              OK
            </StyledButton>
          </DialogActions>
          <Spinner open={duplicating} />
        </Dialog>
      )
    }

    const renderDay = (day) => {
      let dayStudents = students.map((student) => {
        return {
          _id: student._id,
          name: student.name,
          count: 0,
        };
      });
      let activityCount = 0;
      const date = day.getDate();
      const d = DateTime.fromJSDate(day);
      const dateString = d.startOf('day').toUTC().toString();
      for (let activity of activities) {
        if (activity.date === dateString) { //If this date has activities, increment counter
          activityCount++;
          for (let student of activity.students) { //Loop through students of activity and increment counter
            for (let dayStudent of dayStudents) {
              if (student === dayStudent._id) {
                const index = dayStudents.indexOf(dayStudent);
                dayStudents[index].count = dayStudents[index].count + 1;
              };
            };
          };
        };
      };
      if (activityCount) {
        return (
          <Tooltip arrow title={
            <div>
              <Typography variant="body2">Total: {activityCount}</Typography>
              {dayStudents.map((student, i) => {
                if (student.count) {
                  return (
                    <Typography variant="body2" key={i}>{student.name}: {student.count}</Typography>
                  )
                } else {
                  return null
                }
              })}
            </div>
          }>
            <div className={classes.day}>
              {date}
              <div className={classes.activityIndicatorWrapper}>
                <span className={classes.activityIndicator} />
              </div>
            </div>
          </Tooltip>
        )
      } else {
        return (
          <div className={classes.day}>
            {date}
          </div>
        )
      }
    }

    return (
        <Dialog open={showDuplicate} fullScreen={screenIsXs ? true : false}>
          <DialogTitle>
            <div className={classes.dialogTitle}>
              <div className={classes.titleText}>Duplicate</div>
              <Tooltip
                arrow
                placement="bottom-end"
                open={showHelp}
                title={
                  <Typography variant="body2">
                    Select <strong>Scheduled</strong> to create duplicate activities for specific dates, or <strong>Unscheduled</strong> to create duplicate activities without a date.
                  </Typography>
                }
                PopperProps={{
                  disablePortal: true
                }}
              >
                <IconButton
                  onClick={() => {
                    showHelp ? setShowHelp(false) : setShowHelp(true)
                  }}
                  onBlur={() => {
                    setShowHelp(false)
                  }}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DialogTitle>
          <DialogContent>
            <ToggleButtonGroup 
              exclusive
              value={tabValue} 
              className={classes.toggleGroup} 
              onChange={handleTabChange}
            >
              <ToggleButton value={"scheduled"}>Scheduled</ToggleButton>
              <ToggleButton value={"unscheduled"}>Unscheduled</ToggleButton>
            </ToggleButtonGroup>
            { tabValue === "scheduled" &&
              <div>
                <DayPicker
                  selectedDays={selectedDays}
                  onDayClick={handleDayClick}
                  renderDay={renderDay}
                  firstDayOfWeek={1}
                  weekdaysShort="SMTWTFS"
                />
              </div>
            }
            { tabValue === "unscheduled" && 
                <TextField 
                className={classes.fullWidthField} 
                name="noActivities" 
                label="No. Activities" 
                type="number" 
                value={noActivities} 
                onChange={onNoActivitiesChange} 
                inputProps={{
                  min: 1, 
                  max: 20,
                  maxLength: 2
                }} 
              />
            }
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton 
              disabled={ 
                ((tabValue === "scheduled" && !selectedDays.length) 
                || (tabValue === "unscheduled" && noActivities < 1))
                ? true : false 
              }
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              OK
            </StyledButton>
          </DialogActions>
          {confirmModal()}
        </Dialog>
      );
};

export default DuplicateModal;