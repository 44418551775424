import React from 'react';
import CanvasConfetti from "react-canvas-confetti";
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    confetti: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }
}));

const Confetti = ({fire, setFire, clickCoords}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.confetti}
            style={{
            display: fire ? "block" : "none"
            }}
            onClick={() => {setFire(false)}}
        >
            <CanvasConfetti 
                className={classes.confetti}
                fire={fire}
                onDecay={() => {setFire(false)}}
                scalar={0.5}
                startVelocity={25}
                spread={80}
                particleCount={30}
                gravity={1.5}
                colors={[
                    theme.palette.complete.main,
                    theme.palette.complete.shade[200],
                    theme.palette.primary.shade[200],
                    theme.palette.secondary.main,
                ]}
                origin={{
                    x: clickCoords[0],
                    y: clickCoords[1],
                }}
            />
        </div>
    )
}

export default Confetti;