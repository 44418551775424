import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TextField } from "@material-ui/core";
import StyledButton from "../common/StyledButton";

const useStyles = makeStyles((theme) => ({
    fullWidthField: {
      width: "100%",
    },
  }));

const AttachmentLinkModal = ({isOpen, setShowAttachmentLinkModal, projects, projectToInclude, attachmentLinks, setAttachmentLinks}) => {
    const classes = useStyles();
    const [selectedAttachment, setSelectedAttachment] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [linkText, setLinkText] = useState('');
    const [selectableAttachmentsList, setSelectableAttachmentList] = useState([]);

    useEffect(() => {
      const projectObject = projects.filter((project) => projectToInclude === project._id);
      if (projectObject.length) {
        setSelectableAttachmentList(projectObject[0].attachments);
      };
    }, [projects, projectToInclude]);

    const listItems = selectableAttachmentsList.map((attachment, i) => {
        return (
            <MenuItem key={i} value={attachment}>
                {attachment.userFileName}
            </MenuItem>
        )
    });

    const handleChange = (event) => {
        setSelectedAttachment(event.target.value);
    };

    const addAttachmentLink = (event) => {
        event.preventDefault();
        const attachmentLinksTemp = attachmentLinks;
        const attachmentLink = {
          ...selectedAttachment, 
          pageNumber: pageNumber,
          linkText: linkText,
          type: "project"
        };
        setAttachmentLinks([...attachmentLinksTemp, attachmentLink])
        hideModal();
    };
    
    const hideModal = () => {
        setSelectedAttachment('');
        setPageNumber(1);
        setLinkText('');
        setShowAttachmentLinkModal(false);
    };

    const onPageNumberChange = (event) => {
        const pageNumber = Number(event.target.value)
        setPageNumber(pageNumber);
    };

    const onLinkTextChange = (event) => {
        setLinkText(event.target.value);
    };

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle>Project Attachments</DialogTitle>
          <DialogContent>
            <form id="projectAttachmentsForm" onSubmit={addAttachmentLink} autoComplete="off">
                <FormControl className={classes.fullWidthField}>
                    <InputLabel>Attachment</InputLabel>
                    <Select name={"selectedAttachment"} value={selectedAttachment} onChange={handleChange}>
                        {listItems}
                    </Select>
                </FormControl>
                {selectedAttachment.fileType === "application/pdf" && <TextField className={classes.fullWidthField} name="pagenumber" label="Page Number" type="number" value={pageNumber} onChange={onPageNumberChange} inputProps={{min: 1}} />}
                <TextField className={classes.fullWidthField} name="linktext" label="Link Text" value={linkText} onChange={onLinkTextChange} />
            </form>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={() => {hideModal()}}>
              Cancel
            </StyledButton>
            <StyledButton 
                disabled={selectedAttachment ? false : true}
                form="projectAttachmentsForm" 
                type="submit"
            >
              Add
            </StyledButton>
          </DialogActions>
        </Dialog>
      );
};

export default AttachmentLinkModal;