import React, { Fragment } from 'react';
import Category from "./Category";
import { List } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const CategoriesList = ({ categories, setCategories }) => {
    return (
        <Fragment>
            {categories.length === 0 && <Alert severity="info">Add a category</Alert>}
            <List>
                {categories.map((category, i) => <Category key={i} data={category} categories={categories} setCategories={setCategories} />)}
            </List>
        </Fragment>
    )
}

export default CategoriesList;