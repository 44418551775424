import React, { useState } from "react";
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditGoal from '../../containers/goals/EditGoal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    moreButton: {
        right: theme.spacing(1),
    },
    completedCheck: {
        color: theme.palette.complete.main,
    },
}));

const FlaggedGoal = ({goal, goals, setGoals}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showGoalModal, setShowGoalModal] = useState(false);

    const goalMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const goalMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <ListItem className={classes.nested}>
            <ListItemText 
                primary={goal.name}
                primaryTypographyProps={{noWrap: true}}
            />
            {goal.status === "Complete" &&
                <CheckCircleIcon className={classes.completedCheck} />
            }
            <ListItemSecondaryAction className={classes.moreButton}>
                <IconButton
                    onClick={(e) => {
                        goalMenuOpen(e);
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={goalMenuClose}
                >
                    <MenuItem 
                        onClick={() => {
                            setShowGoalModal(true);
                            goalMenuClose();
                        }}
                    >
                        Details
                    </MenuItem>
                </Menu>
                <EditGoal goals={goals} setGoals={setGoals} data={goal} showGoalModal={showGoalModal} setShowGoalModal={setShowGoalModal} />
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default FlaggedGoal;