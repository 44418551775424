import React, { Fragment, useState } from 'react';
import ActivitiesListWeekView from './ActivitiesListWeekView';
import { getFilteredData } from '../../containers/activities/FilterData';
import { DateTime } from "luxon";
import CalendarHeader from './CalendarHeader';

const Calendar = ({ activities, setActivities, students, categories, projects, setNotificationDetails, routineItems, setRoutineItems, navOpen, drawerWidth, bannerClearance }) => {

    const [selectedStudent, setSelectedStudent] = useState(0);
    const [filterIndex, setFilterIndex] = useState(52); //52 is this week when the array starts from a year in the past

    const getDateRange = () => { //Create date range from a year in the past to a year in the future
        const today = DateTime.local().startOf('day');
        const daysSinceMonday= today.weekday - 1;
        const monday = today.minus({days: daysSinceMonday});
        let dateRange = []
        let startDate = monday.minus({days: 364})

        for (let i = 0; i <= 104; i++) {
            dateRange.push([startDate, startDate.plus({days: 6})])
            startDate = startDate.plus({days: 7})
        }
        return dateRange
    };

    const dateRange = getDateRange();

    const getCalendarDays = () => {
        let calendarDays = [];
        let calendarDay = dateRange[0][0];
        do {
            let week = []
            do {
                week.push(calendarDay);
                calendarDay = calendarDay.plus({days: 1});
            }
            while (week.length < 7);
            calendarDays.push(week);
        }
        while (calendarDays.length < dateRange.length);
        return calendarDays
    };

    const calendarDays = getCalendarDays();

    const filters = dateRange.map((weekRange) => {
        return getFilteredData(activities, {
            student: selectedStudent,
            completed: 2, 
            date: {
                mode: 1,
                from: weekRange[0].startOf('day').toUTC().toString(), 
                to: weekRange[1].endOf('day').toUTC().toString()
            }
        })
    });

    return (
        <Fragment>
            <CalendarHeader 
                students={students}
                categories={categories}
                routineItems={routineItems}
                setRoutineItems={setRoutineItems}
                filterIndex={filterIndex}
                setFilterIndex={setFilterIndex}
                selectedStudent={selectedStudent}
                setSelectedStudent={setSelectedStudent}
                dateRange={dateRange}
                navOpen={navOpen}
                drawerWidth={drawerWidth}
                bannerClearance={bannerClearance}
            />
            <ActivitiesListWeekView
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects}
                filterIndex={filterIndex}
                filters={filters}
                selectedStudent={selectedStudent}
                setNotificationDetails={setNotificationDetails}
                calendarDays={calendarDays}
                routineItems={routineItems}
                bannerClearance={bannerClearance}
            />
        </Fragment>
    )
};

export default Calendar;