import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import "./assets/css/react_sortable_tree_overrides.css";

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);
