import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import StyledButton from '../../components/common/StyledButton';
import Routine from '../../components/routine/Routine';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        top: 0,
        right: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    toolbar: theme.mixins.toolbar,
}));

const RoutineConfigButton = ({students, categories, routineItems, setRoutineItems, bannerClearance}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [showRoutine, setShowRoutine] = useState(false);

    return (
        <div className={classes.root} style={{top: bannerClearance}} >
            <div className={classes.toolbar} />
            {!screenIsXs
            ? <StyledButton 
                startIcon={<SettingsIcon />}
                onClick={() => {setShowRoutine(true)}}
            >
                Routine
            </StyledButton>
            : <IconButton
                size="small"
                onClick={() => {setShowRoutine(true)}}
            >
                <SettingsIcon />
            </IconButton>
            }
            <Routine 
                showRoutine={showRoutine} 
                setShowRoutine={setShowRoutine}
                students={students}
                categories={categories}
                routineItems={routineItems}
                setRoutineItems={setRoutineItems}
            />
        </div>
    )
};

export default RoutineConfigButton;