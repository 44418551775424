import React, { useContext, useState, useEffect } from 'react';
import ActivitiesList from '../../components/activities/ActivitiesList';
import StudentFilter from '../../components/common/StudentFilter';
import FilterList from '../../components/activities/FilterList';
import SearchBar from '../../components/activities/SearchBar';
import ExportToPdf from '../../components/activities/ExportToPdf';
import DatePicker from '../../components/common/DatePicker';
import { getFilters, getFilterListItems } from '../activities/CategoryFilters';
import { getAllActivities } from '../../services/activity_services';
import UserContext from '../../UserContext';
import PageListDetail from '../../components/layout/PageListDetail';
import FilterPane from '../../components/layout/FilterPane';
import FilteredContentArea from '../../components/layout/FilteredContentArea';
import TopBar from '../../components/layout/TopBar';

const Report = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {
    
    const { country } = useContext(UserContext);
    const [selectedStudent, setSelectedStudent] = useState(0);
    const [filterIndex, setFilterIndex] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const filterCriteria = {
        student: selectedStudent,
        completed: 1, 
        date: {
            mode: 1,
            from: fromDate,
            to: toDate
        }
}

    const filters = getFilters(activities, categories, filterCriteria);
    const filterListItems = getFilterListItems(activities, categories, setFilterIndex, filterCriteria);

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    return (
        <PageListDetail navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            <FilterPane>
                <StudentFilter 
                    students={students} 
                    selectedStudent={selectedStudent} 
                    setSelectedStudent={setSelectedStudent} 
                />
                <DatePicker country={country} controlName={"fromDate"} controlValue={fromDate} setFunction={setFromDate} label="From" />
                <DatePicker country={country} controlName={"toDate"} controlValue={toDate} setFunction={setToDate} label="To" />
                <FilterList filterListItems={filterListItems} setFilterIndex={setFilterIndex} />
            </FilterPane>
            <FilteredContentArea>
                <TopBar>
                    <SearchBar filterText={filterText} setFilterText={setFilterText} />
                    <ExportToPdf 
                        students={students} 
                        categories={categories}
                        projects={projects}
                        filterIndex={filterIndex}
                        filters={filters}
                        filterText={filterText}
                        sortMethod="dateCompleted" 
                    />
                </TopBar>
                <ActivitiesList 
                    activities={activities} 
                    setActivities={setActivities} 
                    students={students} 
                    categories={categories} 
                    projects={projects}
                    filterIndex={filterIndex}
                    filters={filters}
                    filterText={filterText}
                    selectedStudent={selectedStudent}
                    sortMethod="dateCompleted"
                    setNotificationDetails={setNotificationDetails}
                    hideReorderMenu
                />
            </FilteredContentArea>
        </PageListDetail>
    )
}

export default Report;
