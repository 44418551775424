import React, { Fragment, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import StyledButton from "../common/StyledButton";
import { getAttachmentLink, getAttachmentWithCache } from "../../services/attachment_services";
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import PdfViewer from "../common/PdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../common/Spinner";

const useStyles = makeStyles((theme) => ({
    label: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
  }));

const AttachmentLinks = ({attachmentLinks, setAttachmentLinks, location}) => {
    const classes = useStyles();
    const { country } = useContext(UserContext);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pdfProps, setPdfProps] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDeadLinkWarning, setShowDeadLinkWarning] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);

    const attachmentLinksExist = attachmentLinks.length ? true : false;

    const openAttachmentLink = (link, i) => {
        logAction("Opened attachment link", country, location.pathname, { fileType: link.fileType }); //Mixpanel logging
        let attachmentWindow = window.open('', "_blank");
        attachmentWindow.document.write('Loading attachment...')
        getAttachmentLink({ fileName: link.s3FileName }).then((response) => {
            if (response.data.success) {
                attachmentWindow.location.href = response.data.data.signedUrl;
            } else {
                setDeleteIndex(i);
                setShowDeadLinkWarning(true);
            }        
        });
    };

    const openPdf = (link, i) => {
        logAction("Opening attachment link Pdf", country, location.pathname, { fileType: link.fileType }); //Mixpanel logging
        setShowSpinner(true);
        getAttachmentWithCache({ fileName: link.s3FileName }).then(async (pdfCachePromise) => {
          const pdfBlobResult = await pdfCachePromise;
          if (pdfBlobResult.success) {
            const pdfBlob = pdfBlobResult.pdfBlob;
            logAction("Opened attachment link Pdf from " + pdfBlobResult.method, country, location.pathname, { fileType: link.fileType, errorMessage: pdfBlobResult.errorMessage }); //Mixpanel logging
            setShowSpinner(false);
            setPdfProps({
              name: link.userFileName,
              file: pdfBlob,
              pageNo: link.pageNumber,
            });
            setShowPdfViewer(true);
          } else {
            if (pdfBlobResult.errorMessage === "NotFound") { //If file has been deleted from S3
              logAction("Attachment link Pdf was not found on the server", country, location.pathname, { fileType: link.fileType }); //Mixpanel logging
              setDeleteIndex(i);
              setShowDeadLinkWarning(true);
            } else {
              logAction("Failed to open attachment link Pdf", country, location.pathname, { //Mixpanel logging
                fileType: link.fileType,
                method: pdfBlobResult.method,
                errorMessage: pdfBlobResult.errorMessage
              }); 
            }
            setShowSpinner(false);
          };
        });
    };

    const deleteConfirmModal = () => {
        return (
          <Dialog open={showDeleteConfirm}>
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this attachment link?</Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setDeleteIndex(0);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton onClick={handleDelete}>OK</StyledButton>
            </DialogActions>
          </Dialog>
        );
    };

    const deadLinkWarningModal = () => {
        return (
          <Dialog open={showDeadLinkWarning}>
            <DialogTitle>Attachment Link</DialogTitle>
            <DialogContent>
              <Typography>This attachment no longer exists. Would you like to remove this link?</Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  setShowDeadLinkWarning(false);
                  setDeleteIndex(0);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton onClick={handleDelete}>OK</StyledButton>
            </DialogActions>
          </Dialog>
        );
    };

    const handleDelete = () => {
        logAction("Deleted attachment link", country, location.pathname); //Mixpanel logging
        const attachmentLinksTemp = attachmentLinks;
        attachmentLinksTemp.splice(deleteIndex, 1)
        setAttachmentLinks(attachmentLinksTemp);
        setShowDeleteConfirm(false);
        setShowDeadLinkWarning(false);
        setDeleteIndex(0);
    };

    const onDelete = (i) => {
        setDeleteIndex(i);
        setShowDeleteConfirm(true);
    };

    const chips = attachmentLinks.map((link, i) => {
        if (link.fileType.startsWith("image/")) {
            return <Chip key={i} label={link.linkText} icon={<FontAwesomeIcon icon="image" />} onClick={() => {openAttachmentLink(link, i)}} onDelete={() => {onDelete(i)}} />;
        } else if (link.fileType === "application/pdf") {
            return <Chip key={i} label={link.linkText} icon={<FontAwesomeIcon icon="file-pdf" />} onClick={() => {openPdf(link, i)}} onDelete={() => {onDelete(i)}} />;
        } else {
            return <Chip key={i} label={link.linkText} icon={<FontAwesomeIcon icon="paperclip" />}  onClick={() => {openAttachmentLink(link, i)}} onDelete={() => {onDelete(i)}} />;
        }
    });

    return (
        <Fragment>
            {attachmentLinksExist &&
              <Fragment>
                <InputLabel className={classes.label}>Attachment Links</InputLabel>
                <div>
                    {chips}
                </div>
                <PdfViewer showPdfViewer={showPdfViewer} setShowPdfViewer={setShowPdfViewer} pdfProps={pdfProps} />
                {deleteConfirmModal()}
                {deadLinkWarningModal()}
              </Fragment>
            }
            <Spinner open={showSpinner} />
        </Fragment>
    )
}

export default AttachmentLinks;