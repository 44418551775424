import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
        }, 
    },
}));

const DoubleFabWrapper = ((props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
});

export default DoubleFabWrapper;