import api from "../config/api";

export const createStripePortal = async () => {
  try {
    let result = await api.get("account/createStripePortal");
    // redirect to the Stripe portal
    window.location.href = result.data.portalUrl;
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const createStripeCheckout = async (data = {}) => {
  try {
    let result = await api.post("account/createStripeCheckout", data);
    window.location.href = result.data.redirectUrl;
    // redirect to the Stripe checkout
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const getAccountDetail = async () => {
  try {
    let result = await api.get("account/getAccountDetail");
    if (result.data.accountDetails.subscriptionStatus === "not_yet_created") {
      // create and redirect to checkout session
      result = await api.post("account/createStripeCheckout", {});
      window.location.href = result.data.redirectUrl;
    }
    return result.data.accountDetails;
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};