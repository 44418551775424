import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: theme.palette.common.white,
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    label: {
        paddingTop: theme.spacing(1)
    }
}));

const Spinner = (props) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <div className={classes.wrapper}>
                <CircularProgress color="inherit" />
                { props.label
                ? <Typography className={classes.label}>{props.label}</Typography> 
                : null
                }
            </div>
        </Backdrop>
    )
}

export default Spinner;