import React, {Fragment, useState, useEffect} from 'react';
import { Button, Typography, Collapse, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FindActivitiesModal from '../activities/FindActivitiesModal';
import RoutineStatusIcon from './RoutineStatusIcon'
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    studentRowHeader: {
        display: "flex",
        flexDirection: "row",
        height: theme.spacing(6),
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1),
        alignItems: "center",
        cursor: "pointer",
    },
    statusSummary: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        paddingRight: theme.spacing(0.5),
    },
    studentRowBody: {
        padding: theme.spacing(1),
    },
    categoryButton: {
        marginBottom: theme.spacing(1),
        width: "100%",
    },
    categoryButtonComplete: {
        backgroundColor: theme.palette.complete.shade[50],
        '&:hover': {
            backgroundColor: theme.palette.complete.shade[50]
        },
    },
    categoryButtonLabel: {
        width: "100%",
        textTransform: "none",
        textAlign: "left"
    },
    avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        fontSize: "1em",
        marginRight: theme.spacing(1)
    },
}));

const RoutineStatus = ({activities, setActivities, students, categories, projects, setNotificationDetails, student, routineItemsForDay, dateHeader, studentsExpanded, expandFunction, routineStatusData, studentRoutineItems, maxCategories}) => {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [studentRoutineItemsFiltered, setStudentRoutineItemsFiltered] = useState([]);
    const [categoryStatus, setCategoryStatus] = useState([]);
    const [showFindActivitiesModal, setShowFindActivitiesModal] = useState(false);
    const [categoryId, setCategoryId] = useState("");
    const [checkedActivities, setCheckedActivities] = useState([]);
    const [rowHeight, setRowHeight] = useState(0);

    const today = DateTime.local().startOf('day');
    const dateDiff = dateHeader - today;
    const isPast = dateDiff < 0 ? true : false;
    const singleRowHeight = 55;

    useEffect(() => {
        const studentRoutineItemsFilteredTemp = studentRoutineItems.filter((routineItem) => { 
            if (!routineItem.isLoop) {
                if (routineItem.hasOwnProperty("categories")) {
                    const activeCategories = routineItem.categories.filter((category) => { //filter out deleted categories
                        const matchingCategory = categories.findIndex((c) => c._id === category)
                        if (matchingCategory >= 0) {
                            return category;
                        } else {
                            return null;
                        }
                    });
                    if (activeCategories.length) {
                        routineItem.categories = activeCategories;
                        return routineItem;
                    } else {
                        return null;
                    };
                } else {
                    return routineItem;
                }
            } else {
                return null;
            };
        });
        setStudentRoutineItemsFiltered(studentRoutineItemsFilteredTemp);
        setRowHeight(maxCategories * singleRowHeight + 8) //Extra 8 is for padding
    }, [routineItemsForDay, activities, categories, dateHeader, student, maxCategories, studentRoutineItems]);

    useEffect(() => {
        const categoryStatusNew = studentRoutineItemsFiltered.map((routineItem, i) => {
            if (routineItem.hasOwnProperty("categories")) {
                let categoryRecords = routineItem.categories.map((category) => {
                    const categoryRecord = categories.find((c) => c._id === category);
                    if (categoryRecord) {
                        return categoryRecord;
                    } else {
                        return false;
                    }
                });
                return categoryRecords.map((category) => {
                    let status = "waiting";
                    for (let [categoryId, categoryData] of Object.entries(routineStatusData)) {
                        if (categoryId === category._id) {
                            if (categoryData.completed) {
                                status = "completed"
                            } else if (categoryData.scheduled) {
                                status = "scheduled"
                            }
                        }
                    };
                    return {
                        category: category._id,
                        status: status
                    };
                })
            } else {
                return [];
            }
        })
        setCategoryStatus(categoryStatusNew)
    }, [studentRoutineItemsFiltered, routineStatusData, categories]);

    useEffect(() => {
        const index = studentsExpanded.findIndex((studentExpanded) => studentExpanded.id === student._id)
        if (typeof studentsExpanded[index] !== 'undefined') {
            setExpanded(studentsExpanded[index].expanded);
        };
    }, [studentsExpanded, student]);

    const handleClick = (categoryId) => {
        setCategoryId(categoryId);
        setShowFindActivitiesModal(true);
    }

    const hideModal = () => {
        setCategoryId("");
        setCheckedActivities([])
        setShowFindActivitiesModal(false);
    }

    return (
        <Fragment>
            <div>
                <div className={classes.studentRowHeader} onClick={() => {expandFunction(student._id)}}>
                    {dateHeader.weekday === 1 &&
                        <Fragment>
                            <IconButton>
                                {!expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                            </IconButton>
                            <Typography>{student.name}</Typography>
                        </Fragment>
                    }
                    <div className={classes.statusSummary}>
                        {categoryStatus.map((categoryStatusArray, i) => {
                            if (categoryStatusArray.length) {
                                const statuses = categoryStatusArray.map((categoryStatusItem) => {
                                    if (categoryStatusItem.hasOwnProperty("status")) {
                                        return categoryStatusItem.status
                                    } else {
                                        return null;
                                    }
                                })
                                if (!isPast) {
                                    if (statuses.includes("waiting")) { // if there are ANY waiting
                                        return <RoutineStatusIcon key={i} type="waitingHeader" />;
                                    } else if (statuses.includes("scheduled")) { // if there are NO waiting, but there are scheduled
                                        return <RoutineStatusIcon key={i} type="scheduledHeader" />;
                                    } else if (statuses.includes("completed")) { // if there are only completed
                                        return <RoutineStatusIcon key={i} type="completedHeader" />;
                                    } else {
                                        return null;
                                    }
                                } else {
                                    if (statuses.includes("scheduled")) { // if there are ANY scheduled
                                        return <RoutineStatusIcon key={i} type="scheduledPastHeader" />;
                                    } else if (statuses.includes("waiting") && statuses.includes("completed")) { // if there are NO scheduled, but there are waiting and completed
                                        return <RoutineStatusIcon key={i} type="partiallyCompleteHeader" />;
                                    } else if (statuses.includes("waiting")) { // if there are only waiting
                                        return <RoutineStatusIcon key={i} type="skippedHeader" />;
                                    } else if (statuses.includes("completed")) { // if there are only completed
                                        return <RoutineStatusIcon key={i} type="completedHeader" />;
                                    } else {
                                        return null;
                                    }
                                }
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
                {studentRoutineItemsFiltered.map((routineItem, i) => {
                    return (
                        <Collapse key={i} in={expanded}>
                            <div className={classes.studentRowBody} style={{height: rowHeight}}>
                                {categoryStatus.map((categoryStatusArray) => {
                                    return categoryStatusArray.map((categoryStatusItem) => {
                                        const categoryRecord = categories.find((c) => c._id === categoryStatusItem.category);
                                        if (categoryRecord) {
                                            let statusIcon = null;
                                            switch (categoryStatusItem.status) {
                                                case "waiting":
                                                    statusIcon = 
                                                        !isPast
                                                        ? <RoutineStatusIcon key={i} type="waiting" />
                                                        : <RoutineStatusIcon key={i} type="waitingPast" />
                                                    ;
                                                    break;
                                                case "completed": 
                                                    statusIcon = <RoutineStatusIcon key={i} type="completed" />;
                                                    break;
                                                case "scheduled":
                                                    statusIcon = 
                                                        !isPast
                                                        ? <RoutineStatusIcon key={i} type="scheduled" />
                                                        : <RoutineStatusIcon key={i} type="scheduledPast" />
                                                    ;
                                                    break;
                                                default:
                                                    statusIcon = null;
                                            }
                                            return (
                                                <Button 
                                                    key={routineItem._id + categoryStatusItem.category}
                                                    onClick={() => {handleClick(categoryRecord._id)}}
                                                    startIcon={<FontAwesomeIcon icon={categoryRecord.icon} style={{ color: categoryRecord.color }} size="lg" /> } 
                                                    endIcon={statusIcon}
                                                    className={`${classes.categoryButton} ${categoryStatusItem.status === "completed" && classes.categoryButtonComplete}`}
                                                >
                                                    <Typography variant="body2" className={classes.categoryButtonLabel}>
                                                        {categoryRecord.name}
                                                    </Typography>
                                                </Button>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })
                                })}
                            </div>
                        </Collapse>
                    )}
                )}
            </div>
            <FindActivitiesModal
                activities={activities}
                setActivities={setActivities}
                students={students}
                categories={categories}
                projects={projects}
                checkedActivities={checkedActivities}
                setCheckedActivities={setCheckedActivities}
                setNotificationDetails={setNotificationDetails}
                open={showFindActivitiesModal}
                closeFunction={hideModal}
                studentId={student._id}
                categoryId={categoryId}
                date={dateHeader}
            />
        </Fragment>
    )
}

export default RoutineStatus;