import React, { Fragment, useContext, useState, useEffect, useMemo } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText, useMediaQuery, Divider, ListItemIcon } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import UserContext from '../../UserContext';
import LogoutModal from "../auth/LogoutModal";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeIcon from "@material-ui/icons/DateRange";
import TableChartIcon from "@material-ui/icons/TableChart";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FlagIcon from "@material-ui/icons/Flag";
import FaceIcon from "@material-ui/icons/Face";
import CategoryIcon from "@material-ui/icons/Category";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  activePage: {
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  inactivePage: {
    marginLeft: "4px",
    width: "95%",
  },
  drawerLink: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const NavMenu = ({ navOpen, setNavOpen, drawerWidth, bannerClearance}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);

  const theme = useTheme();
  const screenIsMd = useMediaQuery(theme.breakpoints.down("md"));
  const { userId, firstName, lastName, email, country } = useContext(UserContext);

  const { isReady } = useContext(SplitContext);
  const treatmentName = useMemo(() => {
    return {
      goals: "goals_functionality",
      chatbot: "chatbot"
    }
  }, [])

  const treatment = useTreatments([treatmentName.goals, treatmentName.chatbot]);

  useEffect(() => {
    if (isReady && treatment[treatmentName.chatbot].treatment === "on") {
      const script = document.createElement('script');
      let name = "";
      if (firstName) {
        name = firstName + ' ' + lastName;
      };
      document.tidioIdentify = {
        distinct_id: userId,
        email: email, 
        name: name,
      };
      script.src = "//code.tidio.co/vi0mpp6byef3xcy2ccjef8e8wefinfxp.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isReady, treatment, email, firstName, lastName, treatmentName, userId]);

  function getActivePage() {
    switch (history.location.pathname) {
      case "/myweek":
        return "MyWeek";
      case "/schedule":
        return "Schedule";
      case "/progress":
        return "Progress";
      case "/projects":
        return "Projects";
      case "/report":
        return "Report";
      case "/goals":
        return "Goals";
      case "/students":
        return "Students";
      case "/categories":
          return "Categories";
      case "/account":
        return "Account";
      case "/landing":
        return "Landing";
      default:
        return "";
    }
  }

  function clickAway() {
    if (screenIsMd) {
      setNavOpen(false);
    }
  }

  return (
    <Fragment>
      <Drawer classes={{ paper: classes.paper }} variant={screenIsMd ? "temporary" : "persistent"} open={navOpen} onClick={() => clickAway()}>
        <div className={classes.toolbar} />
        <List style={{ width: drawerWidth }}>
          <Fragment>
            <div style={{height: bannerClearance}} />
            <NavLink
              to="/myweek"
              className={classes.drawerLink}
              onClick={() => {
                logAction("Navigated to My Week", country, location.pathname);
              }}
            >
              <ListItem button key="My Week" className={getActivePage() === "MyWeek" ? classes.activePage : classes.inactivePage}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary="My Week" />
              </ListItem>
            </NavLink>
            <Divider className={classes.divider} />
          </Fragment>

          <NavLink
            to="/schedule"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Schedule", country, location.pathname);
            }}
          >
            <ListItem button key="Schedule" className={getActivePage() === "Schedule" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="Schedule" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/progress"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Progress", country, location.pathname);
            }}
          >
            <ListItem button key="Progress" className={getActivePage() === "Progress" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <TableChartIcon />
              </ListItemIcon>
              <ListItemText primary="Progress" />
            </ListItem>
          </NavLink>

          <NavLink
            to="/projects"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Projects", country, location.pathname);
            }}
          >
            <ListItem button key="Projects" className={getActivePage() === "Projects" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </NavLink>

          {/* FEATURE FLAG */}
          {isReady && treatment[treatmentName.goals].treatment === "on" && (
            <NavLink
              to="/goals"
              className={classes.drawerLink}
              onClick={() => {
                logAction("Navigated to Goals", country, location.pathname);
              }}
            >
              <ListItem button key="Goals" className={getActivePage() === "Goals" ? classes.activePage : classes.inactivePage}>
                <ListItemIcon>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText primary="Goals" />
              </ListItem>
            </NavLink>
          )}

          <NavLink
            to="/report"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Report", country, location.pathname);
            }}
          >
            <ListItem button key="Report" className={getActivePage() === "Report" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Report" />
            </ListItem>
          </NavLink>
          <Divider className={classes.divider} />
          <NavLink
            to="/students"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Students", country, location.pathname);
            }}
          >
            <ListItem button key="Students" className={getActivePage() === "Students" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary="Students" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/categories"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Categories", country, location.pathname);
            }}
          >
            <ListItem button key="Categories" className={getActivePage() === "Categories" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
          </NavLink>
          <Divider className={classes.divider} />
          <NavLink
            to="/account"
            className={classes.drawerLink}
            onClick={() => {
              logAction("Navigated to Account", country, location.pathname);
            }}
          >
            <ListItem button key="Account" className={getActivePage() === "Account" ? classes.activePage : classes.inactivePage}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
          </NavLink>
          <ListItem
            button
            onClick={() => {
              setShowLogout(true);
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <LogoutModal showLogout={showLogout} setShowLogout={setShowLogout} />
    </Fragment>
  );
};

export default NavMenu;