import { 
    faFileDownload, 
    faPaperclip, 
    faFilePdf, 
    faImage,
    faPlus,
    faCalendarCheck,
    faCheckCircle,
    faCalendarMinus,
    faMinus,
    faSquare,
    faAngleUp,
    faAngleDoubleUp,
    faAngleDown,
    faAngleDoubleDown,
    //Category images
    faAward,
    faAtom,
    faBalanceScale,
    faBasketballBall,
    faBell,
    faBible,
    faBicycle,
    faBinoculars,
    faBirthdayCake,
    faBolt,
    faBone,
    faBook,
    faBookReader,
    faBuilding,
    faBus,
    faCalculator,
    faCamera,
    faCampground,
    faCandyCane,
    faCarrot,
    faCashRegister,
    faChartLine,
    faChartPie,
    faChess,
    faChurch,
    faClipboardList,
    faClock,
    faCloudSun,
    faCoffee,
    faCogs,
    faComments,
    faCompass,
    faDice,
    faDna,
    faDog,
    faDollarSign,
    faDove,
    faDragon,
    faExclamation,
    faFilm,
    faFlask,
    faFootballBall,
    faFutbol,
    faGamepad,
    faGavel,
    faGift,
    faGlobeAfrica,
    faGuitar,
    faHamburger,
    faHammer,
    faHandshake,
    faHatWizard,
    faHiking,
    faHorse,
    faHospital,
    faLandmark,
    faLaptop,
    faLeaf,
    faLightbulb,
    faListAlt,
    faMask,
    faMicrophone,
    faMountain,
    faMusic,
    faPalette,
    faPencilAlt,
    faPiggyBank,
    faPlane,
    faPuzzlePiece,
    faQuestion,
    faRobot,
    faRocket,
    faRuler,
    faSearch,
    faSpaceShuttle,
    faStethoscope,
    faStore,
    faSwimmer,
    faTableTennis,
    faThermometerThreeQuarters,
    faTractor,
    faTrophy,
    faUtensils,
    faUserGraduate,    
    faVideo
} from "@fortawesome/free-solid-svg-icons";

import {
    faSquare as farSquare
} from "@fortawesome/free-regular-svg-icons";

const getFaIcons = () => {
    const icons = [
        faFileDownload, 
        faPaperclip, 
        faFilePdf, 
        faImage,
        faPlus,
        faCalendarCheck,
        faCheckCircle,
        faCalendarMinus,
        faMinus,
        faSquare,
        farSquare,
        faAngleUp,
        faAngleDoubleUp,
        faAngleDown,
        faAngleDoubleDown,
        //Category images
        faAward,
        faAtom,
        faBalanceScale,
        faBasketballBall,
        faBell,
        faBible,
        faBicycle,
        faBinoculars,
        faBirthdayCake,
        faBolt,
        faBone,
        faBook,
        faBookReader,
        faBuilding,
        faBus,
        faCalculator,
        faCamera,
        faCampground,
        faCandyCane,
        faCarrot,
        faCashRegister,
        faChartLine,
        faChartPie,
        faChess,
        faChurch,
        faClipboardList,
        faClock,
        faCloudSun,
        faCoffee,
        faCogs,
        faComments,
        faCompass,
        faDice,
        faDna,
        faDog,
        faDollarSign,
        faDove,
        faDragon,
        faExclamation,
        faFilm,
        faFlask,
        faFootballBall,
        faFutbol,
        faGamepad,
        faGavel,
        faGift,
        faGlobeAfrica,
        faGuitar,
        faHamburger,
        faHammer,
        faHandshake,
        faHatWizard,
        faHiking,
        faHorse,
        faHospital,
        faLandmark,
        faLaptop,
        faLeaf,
        faLightbulb,
        faListAlt,
        faMask,
        faMicrophone,
        faMountain,
        faMusic,
        faPalette,
        faPencilAlt,
        faPiggyBank,
        faPlane,
        faPuzzlePiece,
        faQuestion,
        faRobot,
        faRocket,
        faRuler,
        faSearch,
        faSpaceShuttle,
        faStethoscope,
        faStore,
        faSwimmer,
        faTableTennis,
        faThermometerThreeQuarters,
        faTractor,
        faTrophy,
        faUtensils,
        faUserGraduate,
        faVideo
    ]
    
    return icons;
}

export { getFaIcons };