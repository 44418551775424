import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StyledButton from '../common/StyledButton';
import Spinner from '../common/Spinner';
import Attachments from "../common/Attachments";

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    fullWidthField: {
        display: "flex",
        width: "100%",
    },
    name: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
    },
    colorLabel: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    }
}));

const ProjectAttachmentsModal = ({ activities, setActivities, students, categories, projects, isOpen, submitFunction, attachments, setAttachments, location, projectId, projectStudents, projectCategories, setNotificationDetails }) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const [showSpinner, setShowSpinner] = useState(false);
    const [attachmentUploaded, setAttachmentUploaded] = useState(false);

    useEffect(() => {
        if (attachmentUploaded) {
            submitFunction(null, false);
            setAttachmentUploaded(false);
        }
    }, [attachments, attachmentUploaded, submitFunction]);

    return (
        <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"sm"} open={isOpen}>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <IconButton
                        onClick={submitFunction}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    Attachments
                </div>
            </DialogTitle>
            <DialogContent>
                <form id="projectattachments_form" onSubmit={submitFunction} autoComplete="off">
                    <Attachments 
                        attachments={attachments} 
                        setAttachments={setAttachments} 
                        location={location} 
                        setShowSpinner={setShowSpinner}
                        allowAddActivity={true} 
                        optionalContext={{
                            activities: activities,
                            setActivities: setActivities,
                            students: students,
                            categories: categories, 
                            projects: projects,
                            projectId: projectId,
                            projectStudents: projectStudents,
                            projectCategories: projectCategories,
                        }}
                        setNotificationDetails={setNotificationDetails}
                        setAttachmentUploaded={setAttachmentUploaded}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton form="projectattachments_form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
            <Spinner open={showSpinner} />
        </Dialog>
    )
}

export default ProjectAttachmentsModal;