import React, { Fragment } from 'react';
import { MenuItem, FormControl, Select, InputLabel, ListItemIcon, ListItemText, Chip, SvgIcon, ListItemAvatar } from '@material-ui/core';
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { getProjectIcons } from "../../containers/projects/ProjectIcons";
import ProjectPlanLogo from './ProjectPlanLogo';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%",
    },
    categoryChip: {
      marginRight: theme.spacing(1),
    },
    projectLogo: {
      height: "24px",
      width: "24px",
    }
}));

const ProjectSelector = ({ projects, projectToInclude, setProjectToInclude }) => {

    const classes = useStyles();
    const theme = useTheme();
    const projectIcons = getProjectIcons();

    let selectableProjectList = [{_id: '', name: 'None'}, ...projects];
    const handleChange = (event) => {
      setProjectToInclude(event.target.value);
    };

    const listItems = selectableProjectList.map((project) => {
      if (!project.dateCompleted || project._id === projectToInclude) { //Hide projects that are completed unless the project is already associated with the activity
        return (
          <MenuItem key={project._id} value={project._id}>
            { project.logoUrl 
            ? <ListItemAvatar>
              <ProjectPlanLogo name={project.name} logoUrl={project.logoUrl} className={classes.projectLogo} />
            </ListItemAvatar>
            : <ListItemIcon>
              <SvgIcon className={classes.projectIcon} component={projectIcons[project.icon]} viewBox="0 0 128 128" />
            </ListItemIcon>
            }
            <ListItemText>
              {project.name}
            </ListItemText>
          </MenuItem>
        )
      } else {
        return null;
      }
    });

    const getRenderValue = (value) => {
      const projectRecord = projects.find((project) => project._id === value)
      if (projectRecord) {
        return (
          <Chip 
            avatar={projectRecord.logoUrl 
              ? <ProjectPlanLogo name={projectRecord.name} logoUrl={projectRecord.logoUrl} />
              : null
            }
            icon={!projectRecord.logoUrl
              ? <SvgIcon 
                className={classes.projectIcon}
                component={projectIcons[projectRecord.icon]} 
                viewBox="0 0 128 128" 
              />
              : null
            } 
            label={projectRecord.name} 
            variant="outlined"
            style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
          />
        )
      } else {
        return false;
      }
  };

  return (
    <Fragment>
      <FormControl className={classes.fullWidth}>
        <InputLabel>Project</InputLabel>
        <Select 
          name={"selectedProject"} 
          value={projectToInclude} 
          renderValue={getRenderValue}
          onChange={handleChange}>
          {listItems}
        </Select>
      </FormControl>
    </Fragment>
  );
}

export default ProjectSelector;