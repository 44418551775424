import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem } from '@material-ui/core';
import Dropzone from "react-dropzone";
import FileRejectionModal from '../../common/FileRejectionModal';
import Spinner from '../../common/Spinner';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: theme.palette.grey[400],
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
    outline: "none",
    transition: "border .24s ease-in-out",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    textAlign: "center",
  },
  completedCheck: {
    color: theme.palette.complete.main,
},
}));

const AttachmentUpload = ({ projectPlans, selectedProjectPlan, attachmentsForUpload, setAttachmentsForUpload }) => {

    const classes = useStyles();
    const [verifying, setVerifying] = useState(false);
    const [showFileRejections, setShowFileRejections] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);

    let projectPlanDetails = projectPlans.filter((projectPlan) => projectPlan._id === selectedProjectPlan);
    projectPlanDetails = projectPlanDetails[0];
    
    const attachmentValidator = (file) => {
        let isMatch = false;
        for (let requiredAttachment of projectPlanDetails.requiredAttachments) {
            const size = parseInt(requiredAttachment.size)
            if (
                file.name === requiredAttachment.name &&
                file.type === requiredAttachment.type &&
                file.size === size
            ) {
                isMatch = true;
            } else {
                /*
                console.log({
                    expectedFileSize: size,
                    actualFileSize: file.size
                })
                */
            };
        };
        if (isMatch) {
            return null
        } else {
            return {
                code: "invalid",
                message: "invalid file"
            }
        }
    }

    const handleUpload = (acceptedFiles) => {
        let attachmentsForUploadCopy = [...attachmentsForUpload]
        for (let acceptedFile of acceptedFiles) { //Check for duplicates
            let isDuplicate = false;
            for (let attachmentForUpload of attachmentsForUpload) {
                if (acceptedFile.name === attachmentForUpload.name) {
                    isDuplicate = true;
                }
            }
            if (!isDuplicate) {
                attachmentsForUploadCopy.push(acceptedFile)
            }
        }
        setAttachmentsForUpload(attachmentsForUploadCopy)
        setVerifying(false)
      };

    const handleFileRejection = (files) => {
        const fileList = files.map((file) => {
          return file.file;
        });
        setRejectedFiles(fileList);
        setShowFileRejections(true);
    };

    const requiredAttachments = () => {
        let returnData = [];
        let i = 0;
        for (let requiredAttachment of projectPlanDetails.requiredAttachments) {
            let isAccepted = false;
            for (let attachmentForUpload of attachmentsForUpload) {
                if (requiredAttachment.name === attachmentForUpload.name) {
                    isAccepted = true;
                }
            }
            if (isAccepted) {
                returnData.push(<ListItem key={i}>{requiredAttachment.name} <CheckCircleIcon className={classes.completedCheck} /></ListItem>)
            } else {
                returnData.push(<ListItem key={i}>{requiredAttachment.name}</ListItem>)
            }
            i = i + 1
        }
        return (
            <List>
                {returnData.map((lineItem) => {return lineItem})}
            </List>
        )
    }

    return (
        <Fragment>
            <Typography>Please provide the following attachments...</Typography>
            {requiredAttachments()}
            <Dropzone
                validator={attachmentValidator}
                onDrop={(acceptedFiles) => {
                    setVerifying(true);
                    handleUpload(acceptedFiles);
                }}
                onDropRejected={(files) => {
                    handleFileRejection(files);
                }}
                >
                {({ getRootProps, getInputProps }) => (
                    <div className={classes.dropzone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                        Drag files here, or click to select files
                    </p>
                    </div>
                )}
            </Dropzone>
            <Spinner open={verifying} />
            <FileRejectionModal 
                showFileRejections={showFileRejections} 
                setShowFileRejections={setShowFileRejections} 
                rejectedFiles={rejectedFiles} 
                title={"Attachment Error"}
                message={"The following files are not required and will be ignored..."}
                showFileSize={false}
            />
        </Fragment>
    )
}

export default AttachmentUpload;