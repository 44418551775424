import api from "../config/api";

export const updateUserDetails = async (data) => {
  try {
    return await api.post("user/updateUserDetails", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateTermsVersion = async () => {
  try {
    return await api.post("user/updateTermsVersion");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const updateShowTutorial = async (data) => {
  try {
    return await api.post("user/updateShowTutorial", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};