import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TodayIcon from "@material-ui/icons/Today";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 0,
  },
}));

const ActivityNotification =({ notificationDetails = {} }) => {
  //expected notificationDetails props are icon, title and message
  const classes = useStyles();
  const [showNotification, setShowNotification] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowNotification(false);
  };

  let iconComponent = null;

  useEffect(() => {
    if (Object.keys(notificationDetails).length) {
      setShowNotification(true);
    };
}, [notificationDetails]);

  switch (notificationDetails.icon) {
    case "add":
      iconComponent = <FileCopyIcon fontSize="inherit" />;
      break;
    case "calendar":
      iconComponent = <TodayIcon fontSize="inherit" />;
      break;
    default:
      iconComponent = null;
  };

  return (
    <div>
      <Snackbar
        key={notificationDetails.key}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={showNotification}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert icon={iconComponent} severity="success" onClose={handleClose}>
          {notificationDetails.title && (
            <AlertTitle className={classes.title}>
              {notificationDetails.title}
            </AlertTitle>
          )}
          {notificationDetails.message && notificationDetails.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ActivityNotification;