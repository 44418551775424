import React, { Fragment } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

const useStyles = makeStyles(theme => ({
    date: {
        width: "100%"
    },
}));

const DatePicker = ({ country, controlName, controlValue, setFunction, label = "Date" }) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down('xs'));
    const dateformat = country === 'US' ? "MM/dd/yyyy" : "dd/MM/yyyy";

    const handleDateChange = date => {
        if (date != null) {
            date = date.startOf('day').toUTC().toString();
        };
        setFunction(date);
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDatePicker className={classes.date}
                    disableToolbar
                    variant={screenIsXs ? 'dialog' : 'inline'}
                    format={dateformat}
                    margin="normal"
                    name={controlName}
                    label={label}
                    autoOk="true"
                    value={controlValue}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    )
}

export default DatePicker;