import React, { useState, useContext, useEffect, Fragment } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, ListItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import StyledButton from "../common/StyledButton";
import UserDetails from "../common/UserDetails";
import UserContext from "../../UserContext";
import Terms from "./Terms";
import Tutorial from "./Tutorial";
import { getAccountDetail, createStripeCheckout } from "../../services/account_services";
import { updateProjects, checkForProjectUpdates } from "../../utils/ProjectUpdates";
import Logout from "../../containers/auth/Logout";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import Spinner from "../common/Spinner";

const useStyles = makeStyles(theme => ({
  successIcon: {
    color: theme.palette.complete.main,
    cursor: "pointer",
    marginLeft: theme.spacing(1)
  },
  failureIcon: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1)
  }, 
  infoIcon: {
    color: theme.palette.info.main,
    cursor: "pointer",
    marginLeft: theme.spacing(1)
  },
  bulletList: {
    listStyleType: "disc",
    marginLeft: theme.spacing(2)
  },
  bulletItem: {
    display: "list-item",
    padding: 0,
  },
  bulletItemContent: {
    display: "flex",
    alignItems: "center"
  }
}));

const LoginNotification = ({projects = []}) => {
  const classes = useStyles();
  const location = useLocation();

  const [showModalNotWhitelisted, setShowModalNotWhitelisted] = useState(false);
  const [showModalEmailNotVerified, setShowModalEmailNotVerified] = useState(false);
  const [showModalMissingUserDetails, setShowModalMissingUserDetails] = useState(false);
  const [showModalAccountInactive, setShowModalAccountInactive] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalTutorial, setShowModalTutorial] = useState(false);
  const [showModalProjectUpdateConfirm, setShowModalProjectUpdateConfirm] = useState(false);
  const [showModalProjectUpdateResults, setShowModalProjectUpdateResults] = useState(false);
  const [checkedForProjectUpdates, setCheckedForProjectUpdates] = useState(false);
  const [hasSeenEmailNotVerified, setHasSeenEmailNotVerified] = useState(false);
  const [updatingProjects, setUpdatingProjects] = useState(false);
  const [projectUpdatesRequired, setProjectUpdatesRequired] = useState({
    major: [],
    minor: [],
    patch: []
  });
  const [projectUpdateResults, setProjectUpdateResults] = useState({
    major: [],
    minor: [],
    patch: []
  });

  const { firstName, lastName, email, country, emailVerified, showTutorial, hasAcceptedLatestTerms, dateJoined, debugMode } = useContext(UserContext);
  const [accountDetail, setAccountDetail] = useState({});
  const [logoutTrigger, setLogoutTrigger] = useState(false);

  const graceDays = 3; //The number of days to allow accounts with unverified email to log in

  useEffect(() => {
    getAccountDetail()
      .then((accountDetail) => {
        setAccountDetail(accountDetail);
      })
      .catch((error) => {
        setAccountDetail({});
      });
  }, []);

  useEffect(() => {
    if (accountDetail.hasOwnProperty("whitelistAccess") && !accountDetail.whitelistAccess) {
      logAction("Viewed login notification: Not on whitelist", country, location.pathname); //Mixpanel logging
      handleClose(false); //Close modals that might have been opened before data was loaded
      setShowModalNotWhitelisted(true);
    } else {
      switch (accountDetail.subscriptionStatus) {
        case "incomplete": 
        case "incomplete_expired": 
        case "unpaid":
        case "canceled":
          logAction("Viewed login notification: Account inactive", country, location.pathname); //Mixpanel logging
          setShowModalAccountInactive(true);
          break;
        default:
          if (!emailVerified && !hasSeenEmailNotVerified) { //User will only see this once per session (or until browser is refreshed)
            logAction("Viewed login notification: Email not verified", country, location.pathname); //Mixpanel logging
            setShowModalEmailNotVerified(true);
          } else if (!firstName || !lastName || !email) {
            logAction("Viewed login notification: Missing user details", country, location.pathname); //Mixpanel logging
            setShowModalMissingUserDetails(true);
          } else if (!hasAcceptedLatestTerms) { 
            logAction("Viewed login notification: Terms and Conditions", country, location.pathname); //Mixpanel logging
            setShowModalTerms(true);
          } else if (showTutorial) {
            logAction("Viewed login notification: Tutorial", country, location.pathname); //Mixpanel logging
            setShowModalTutorial(true);
          } else if (!checkedForProjectUpdates) { 
            checkForProjectUpdates(projects, setProjectUpdatesRequired, setShowModalProjectUpdateConfirm, setUpdatingProjects, setCheckedForProjectUpdates, setProjectUpdateResults, setShowModalProjectUpdateResults, debugMode);
          }
      };
    };
  }, [firstName, lastName, email, emailVerified, accountDetail, hasAcceptedLatestTerms, showTutorial, country, location, hasSeenEmailNotVerified, projects, checkedForProjectUpdates, checkForProjectUpdates, debugMode]);

  const handleClose = () => {
    setShowModalNotWhitelisted(false);
    setShowModalEmailNotVerified(false);
    setShowModalMissingUserDetails(false);
    setShowModalAccountInactive(false);
    setShowModalTerms(false);
    setShowModalTutorial(false);
  }

  const handleLogout = () => {
    handleClose();
    setLogoutTrigger(true);
  }

  const openStripeCheckout = () => {
    logAction("Clicked Reactivate", country, location.pathname); //Mixpanel logging
    createStripeCheckout({resubscribe: true});
  }

  const postponeEmailNotVerified = () => {
    setHasSeenEmailNotVerified(true);
    setShowModalEmailNotVerified(false);
  }

  const modalNotWhitelisted = () => {
    return (
      <Dialog open={showModalNotWhitelisted}>
        <DialogTitle>
          Account Status
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>It looks like you don't have access to Freely!</Typography>
          <Typography paragraph>If you think this is incorrect please let us know by emailing <strong>contact@learnfreely.app</strong>.</Typography>
        </DialogContent>
        <DialogActions>
            <StyledButton onClick={() => handleLogout()}>Logout</StyledButton>
        </DialogActions>
      </Dialog>
    )
  }

  const modalEmailNotVerified = () => {
    const dateJoinedObj = DateTime.fromISO(dateJoined)
    const today = DateTime.local();
    const daysSinceJoined = today.diff(dateJoinedObj, "days").days;

    return (
      <Dialog open={showModalEmailNotVerified}>
        <DialogTitle>
          Account Status
        </DialogTitle>
        <DialogContent>
          <Typography>Your account is not yet active. Please check your inbox and click the <strong>confirm my account</strong> link to verify your email and then try again.</Typography>
        </DialogContent>
        <DialogActions>
          {
            daysSinceJoined < graceDays
            ? <StyledButton onClick={() => postponeEmailNotVerified()}>OK</StyledButton>
            : <StyledButton onClick={() => handleLogout()}>Logout</StyledButton>
          }
        </DialogActions>
      </Dialog>
    )
  }

  const modalMissingUserDetails = () => {
    return (
      <Dialog open={showModalMissingUserDetails}>
        <UserDetails 
          showModal={showModalMissingUserDetails} 
          handleClose={() => {setShowModalMissingUserDetails(false)}}
          message={<Alert severity="error">Please ensure your details are complete and correct</Alert>}
        />
      </Dialog>
    )
  }

  const modalAccountInactive = () => {
    return (
      <Dialog open={showModalAccountInactive}>
        <DialogTitle>
          Account Status
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>Your account has become inactive.</Typography>
        </DialogContent>
        <DialogActions>
            <StyledButton onClick={() => openStripeCheckout()}>Reactivate</StyledButton>
            <StyledButton onClick={() => handleLogout()}>Logout</StyledButton>
        </DialogActions>
      </Dialog>
    )
  }

  const modalProjectUpdateConfirm = () => {
    const onContinue = () => {
      setShowModalProjectUpdateConfirm(false);
      updateProjects(projectUpdatesRequired, setUpdatingProjects, setCheckedForProjectUpdates, setProjectUpdateResults, setShowModalProjectUpdateResults);
    }

    const onCancel = () => {
      setShowModalProjectUpdateConfirm(false);
      setCheckedForProjectUpdates(true); //Ignore updates for now
    }

    const projectListItem = (project, key, updateType) => {
      return (
        <ListItem key={key} className={classes.bulletItem}>
          <div className={classes.bulletItemContent}>
            {project.name}
            <Tooltip title={
              <ul>
                <li>{`User version: ${project.projectPlan.versionImported.major}.${project.projectPlan.versionImported.minor}.${project.projectPlan.versionImported.patch}`}</li>
                <li>{`Update type: ${updateType}`}</li>
              </ul>
            }>
              <InfoIcon className={classes.infoIcon}/>
            </Tooltip>
          </div>
        </ListItem>
      )
    }

    return (
      <Dialog open={showModalProjectUpdateConfirm}>
        <DialogTitle>
          Project Update
        </DialogTitle>
        <DialogContent>
          <Typography>The following project(s) require updates...</Typography>
          <List className={classes.bulletList}>
            {projectUpdatesRequired.major.map((project, i) => projectListItem(project, i, "major"))}
            {projectUpdatesRequired.minor.map((project, i) => projectListItem(project, i, "minor"))}
            {projectUpdatesRequired.patch.map((project, i) => projectListItem(project, i, "patch"))}
          </List>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => onCancel()}>Cancel</StyledButton>
          <StyledButton onClick={() => onContinue()}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    )
  }

  const modalProjectUpdateResults = () => {

    const successMajor = projectUpdateResults.major.filter((project) => project.success)
    const successMinor = projectUpdateResults.minor.filter((project) => project.success)
    const successPatch = projectUpdateResults.patch.filter((project) => project.success)
    const successCount = successMajor.length + successMinor.length + successPatch.length
     
    const failureMajor = projectUpdateResults.major.filter((project) => !project.success)
    const failureMinor = projectUpdateResults.minor.filter((project) => !project.success)
    const failurePatch = projectUpdateResults.patch.filter((project) => !project.success)
    const failureCount = failureMajor.length + failureMinor.length + failurePatch.length

    const resultListItem = (project, key, updateType) => {
      return (
        <ListItem key={key} className={classes.bulletItem}>
          <div className={classes.bulletItemContent}>
            {project.name}
            {project.success
            ? <Tooltip title={`Updated to version ${project.version.major}.${project.version.minor}.${project.version.patch}`}>
              <CheckCircleIcon className={classes.successIcon} />
            </Tooltip>
            : <CancelIcon className={classes.failureIcon} />
            }
            {
              debugMode //User has debugMode flag enabled
              ? <Tooltip title="Click to write results to console">
                <InfoIcon className={classes.infoIcon} onClick={() => console.log(project.activityResults)} />
              </Tooltip>
              : null
            }
          </div>
        </ListItem> 
      )
    }

    return (
      <Dialog open={showModalProjectUpdateResults}>
        <DialogTitle>
          Project Update Results
        </DialogTitle>
        <DialogContent>
          {successCount
          ? <Fragment>
            <Typography>The following project(s) have been updated with changes from the project author...</Typography>
            <List className={classes.bulletList}>
              {projectUpdateResults.major.filter((project) => project.success).map((project, i) => resultListItem(project, i, "major"))}
              {projectUpdateResults.minor.filter((project) => project.success).map((project, i) => resultListItem(project, i, "minor"))}
              {projectUpdateResults.patch.filter((project) => project.success).map((project, i) => resultListItem(project, i, "patch"))}
            </List>
            </Fragment>
          : null
          }
          {failureCount
          ? <Fragment>
            <Typography>The following project(s) require updates from the project author, but the update failed. If the problem persists, please contact us at contact@learnfreely.app</Typography>
            <List className={classes.bulletList}>
              {projectUpdateResults.major.filter((project) => !project.success).map((project, i) => resultListItem(project, i, "major"))}
              {projectUpdateResults.minor.filter((project) => !project.success).map((project, i) => resultListItem(project, i, "minor"))}
              {projectUpdateResults.patch.filter((project) => !project.success).map((project, i) => resultListItem(project, i, "patch"))}
            </List>
          </Fragment>
          : null
          }
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setShowModalProjectUpdateResults(false)}>OK</StyledButton>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Fragment>
      {modalNotWhitelisted()}
      {modalEmailNotVerified()}
      {modalMissingUserDetails()}
      {modalAccountInactive()}
      {modalProjectUpdateConfirm()}
      {modalProjectUpdateResults()}
      <Terms showModalTerms={showModalTerms} setShowModalTerms={setShowModalTerms} handleLogout={handleLogout} />
      <Tutorial showModalTutorial={showModalTutorial} setShowModalTutorial={setShowModalTutorial} />
      <Logout trigger={logoutTrigger} />
      <Spinner open={updatingProjects} label="Updating projects. Please wait." />
    </Fragment>
  )
}

export default LoginNotification;