import React, { useState, useEffect, Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, useTheme, useMediaQuery, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StyledButton from '../common/StyledButton';
import StudentSelector from "../common/StudentSelector";
import { duplicateProjectActivities } from '../../services/project_services';
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import { useLocation } from "react-router-dom";
import Spinner from "../common/Spinner";
import ProjectDuplicateOptionStudentGrouping from './projectDuplicateOptionStudentGrouping';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    titleText: {
        flexGrow: 1
    },
    switch: {
        display: "flex",
        flexDirection: "row",
        marginTop: theme.spacing(2),
    },
    options: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    optionsRow: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1),
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    optionsRowLabel: {
        flexGrow: 1
    },
    toggleButton: {
        width: 100
    },
    confirmationDetails: {
        marginTop: theme.spacing(4),
    }
}));

const ProjectDuplicateModal = ({ activities, setActivities, students, projects, setProjects, isOpen, setShowDuplicateProjectModal, filters, projectId }) => {

    const classes = useStyles();
    const theme = useTheme();
    const [studentsToInclude, setStudentsToInclude] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [optionParticipation, setOptionParticipation] = useState("together");
    const [optionActivityDates, setOptionActivityDates] = useState("blank");
    const [optionCompletedActivities, setOptionCompletedActivities] = useState(false);
    const [studentGroups, setStudentGroups] = useState([]);
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const { country } = useContext(UserContext);

    const location = useLocation();

    const incompleteActivityCount = filters[0].length;
    const completedActivityCount = filters[1].length;
    const projectObj = projects.filter((project) => project._id === projectId).length ? projects.filter((project) => project._id === projectId)[0] : {};

    useEffect(() => {
        setStudentGroups([studentsToInclude]);
    }, [studentsToInclude]);
    
    const handleActivityDatesChange = (event, newValue) => {
        if (newValue) {
            setOptionActivityDates(newValue);
        }
    };

    const hideModal = () => {
        setStudentsToInclude([]);
        setShowConfirm(false);
        setShowHelp(false);
        setShowDuplicateProjectModal(false);
        setOptionParticipation("together");
        setOptionActivityDates("blank");
        setOptionCompletedActivities(false);
    }

    const handleConfirm = () => {
        const studentGroupsTemp = studentGroups.filter((studentGroup) => studentGroup.length); //Remove empty arrays
        setStudentGroups(studentGroupsTemp);
        setShowConfirm(true);
    }

    const onSubmit = () => {
        setDuplicating(true);
        const submitData = {
            projectId: projectId,
            studentGroups: studentGroups
        }
        duplicateProjectActivities(submitData).then((response) => {
            logAction("Duplicated project activities", country, location.pathname, submitData); //Mixpanel logging
            const newActivities = response.data.data;
            let projectIndex = projects.findIndex(project => project._id === projectId);
            let projectsCopy = [...projects];
            let projectStudents = projectsCopy[projectIndex].students;
            for (let studentGroup of studentGroups) {
                projectStudents = projectStudents.concat(studentGroup);
            };
            projectsCopy[projectIndex].students = projectStudents;
            setProjects(projectsCopy)
            setActivities([...activities, ...newActivities])
            setDuplicating(false);
            hideModal();
        }).catch(error =>{
            console.log("An error occured during project activity duplication:", error);
        });
    }

    const confirmModal = () => {
        let studentsToUnassign = [];
        if (projectObj.hasOwnProperty("students")) {
            const studentsToUnassign = projectObj.students.filter((studentId) => { //This identifies students that have been selected for duplication and are also assigned to the project. This is currently prevented via the UI but may be enabled later
                return studentsToInclude.some((s) => s === studentId)
            })
        };

        const activityMultiplier = studentGroups.length
        const noActivitiesPerStudent = optionCompletedActivities ? incompleteActivityCount + completedActivityCount : incompleteActivityCount 
        const noActivitiesTotal = noActivitiesPerStudent * activityMultiplier

        return (
            <Dialog open={showConfirm}>
              <DialogTitle>Confirm</DialogTitle>
              <DialogContent>
                <Typography gutterBottom>Please confirm that these details are correct. This action cannot be undone.</Typography>
                <div className={classes.confirmationDetails}>
                    {
                        studentsToUnassign.length
                        ? <Fragment>
                            <Typography variant="subtitle2">Update existing activities</Typography>
                            {/* <Typography variant="body2"> */}
                                <ul>
                                    <li>
                                        {`Unassign `} 
                                        {//List out the students to unassign i.e. students that will be on the new activities that are also assigned to the project
                                            studentsToUnassign.map((studentToUnassign, i) => {
                                                let matchingStudent = students.filter((student) => student._id === studentToUnassign)
                                                matchingStudent = matchingStudent[0]
                                                return <span key={i}>{`${matchingStudent.name}${i !== studentsToUnassign.length - 1 ? ", " : ""} `}</span>
                                            })
                                        }
                                        {//Distinguish between outstanding and completed activities
                                            `from ${incompleteActivityCount} ${completedActivityCount ? "outstanding": ""} ${incompleteActivityCount > 1 ? "activities": "activity"}`
                                        } 
                                    </li>
                                    {
                                        completedActivityCount
                                        ? <li>The completed activities will be left as is</li>
                                        : null
                                    }
                                    {//If they are creating new activities for all of the students that are assigned to the project, there's a good chance that the existing activities will end up with no students assigned (and need to be manually deleted)
                                        studentsToUnassign.length === projectObj.students.length
                                        ? <li>Note: This may result in there being no students left assigned to the existing activities</li>
                                        : null
                                    }
                                </ul>
                            {/* </Typography> */}
                        </Fragment>
                        : null
                    }
                    {
                        studentGroups.length
                        ? <Fragment>
                            {/* <Typography variant="subtitle2">Add new activities</Typography> */}
                            {/* <Typography variant="body2"> */}
                            <ul>
                                    <li>{`Add ${noActivitiesTotal} new activities`}</li>
                                    <ul>
                                        <li>{`They will be duplicates of the existing ${completedActivityCount ? "outstanding" : ""} ${optionCompletedActivities ? "and completed " : ""}activities`}</li>
                                        {//List out the groups and students to be allocated
                                            studentGroups.length > 1
                                            ? studentGroups.map((studentGroup, index) => {
                                                return (
                                                    <li key={index}>
                                                        {
                                                            studentGroup.map((s, i) => {
                                                                let matchingStudent = students.filter((student) => student._id === s)
                                                                matchingStudent = matchingStudent[0]
                                                                return <span key={i}>{`${matchingStudent.name}${i !== studentGroup.length - 1 ? ", " : ""} `}</span>
                                                            })
                                                        }
                                                        {` will be assigned to ${noActivitiesPerStudent} of them`}
                                                    </li>
                                                )
                                            })
                                            : <li>
                                                {
                                                    studentGroups[0].map((s, i) => {
                                                        let matchingStudent = students.filter((student) => student._id === s)
                                                        matchingStudent = matchingStudent[0]
                                                        return <span key={i}>{`${matchingStudent.name}${i !== studentGroups[0].length - 1 ? ", " : ""} `}</span>
                                                    })
                                                }
                                                {` will be assigned to all of them`}
                                            </li>
                                        }
                                    </ul>
                                    {completedActivityCount && !optionCompletedActivities ? <li>The completed activities will not be duplicated</li> : null}
                                    {/* We will default to blank for now, but may allow users to choose later
                                        <li>
                                            {
                                                optionActivityDates === "blank"
                                                ? "The date on these activities will be blank"
                                                : "The date on these activities will be the same as those on the existing activities"
                                            }
                                        </li> 
                                    */}
                                </ul>
                            {/* </Typography> */}
                        </Fragment>
                        : null
                    }
                </div>
              </DialogContent>
              <DialogActions>
                <StyledButton
                  onClick={() => {
                    setShowConfirm(false);
                  }}
                >
                  Cancel
                </StyledButton>
                <StyledButton 
                  onClick={onSubmit}
                >
                  OK
                </StyledButton>
              </DialogActions>
              <Spinner open={duplicating} />
            </Dialog>
          )
    }

    return (
        <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"sm"} open={isOpen}>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <IconButton
                        onClick={hideModal}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <div className={classes.titleText}>Duplicate Project Activities</div>
{/*                     <Tooltip
                        arrow
                        placement="bottom-end"
                        open={showHelp}
                        title={
                        <Typography variant="body2">
                            This will create duplicates of all project activities and assign the selected student(s) to them. If the selected student(s) are currently assigned to the existing activities, they will be unassigned from them.
                        </Typography>
                        }
                        PopperProps={{
                        disablePortal: true
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                showHelp ? setShowHelp(false) : setShowHelp(true)
                            }}
                            onBlur={() => {
                                setShowHelp(false)
                            }}
                            >
                            <HelpIcon />
                        </IconButton>
                    </Tooltip> */}
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Select student(s) to assign the new activities to</Typography>
                <StudentSelector students={students} studentsToInclude={studentsToInclude} setStudentsToInclude={setStudentsToInclude} studentsToDisable={projectObj.students} />
                <div className={classes.options}>
                    {/* //Possibly enable later if requested by users
                        studentsToInclude.length
                        ? <div className={classes.optionsRow}>
                            <div className={classes.optionsRowLabel}>
                                <Typography>The dates of the new activities should be</Typography>
                            </div>
                            <ToggleButtonGroup 
                                exclusive
                                size={"small"}
                                value={optionActivityDates} 
                                onChange={handleActivityDatesChange}
                                >
                                <ToggleButton className={classes.toggleButton} value={"blank"}>Blank</ToggleButton>
                                <ToggleButton className={classes.toggleButton} value={"same"}>The Same</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        : null
                    */}
                    <ProjectDuplicateOptionStudentGrouping 
                        students={students}
                        studentsToInclude={studentsToInclude} 
                        optionParticipation={optionParticipation}
                        setOptionParticipation={setOptionParticipation}
                        studentGroups={studentGroups}
                        setStudentGroups={setStudentGroups}
                    />
                    {/* //Enable later once users are trained not to enter observation notes into the description
                        studentsToInclude.length && completedActivityCount ?
                        <div className={classes.optionsRow}>
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        checked={optionCompletedActivities}
                                        onChange={() => {
                                            optionCompletedActivities ? setOptionCompletedActivities(false) : setOptionCompletedActivities(true);
                                        }}
                                    />
                                }
                                label="Include Completed Activities"
                            />
                        </div>
                        : null
                    */}
                </div>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={hideModal}>
                    Cancel
                </StyledButton>
                <StyledButton 
                    disabled={studentsToInclude.length ? false : true}
                    onClick={handleConfirm}
                >
                    OK
                </StyledButton>
            </DialogActions>
            {confirmModal()}
        </Dialog>
    )
}

export default ProjectDuplicateModal;