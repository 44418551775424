import { makeStyles } from "@material-ui/core/styles";
import PageBase from "./PageBase";

const useStyles = makeStyles((theme) => ({
  root: {
      display: "flex",
      flexDirection: "row",
      height: "calc(99vh - " + theme.mixins.toolbar.minHeight + "px)",
      [theme.breakpoints.down('xs')]: {
          height: "auto",
          flexDirection: "column",
      }
  }
}));

const Page = (props) => {
    const classes = useStyles();

    return (
      <PageBase navOpen={props.navOpen} drawerWidth={props.drawerWidth} bannerClearance={props.bannerClearance}>
        <div style={{height: props.bannerClearance}} />
        <div className={classes.root}>
          {props.children}
        </div>
      </PageBase>
    )
};

export default Page;