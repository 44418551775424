import api from "../config/api";

export const getProjectPlans = async () => {
  try {
    return await api.get("projectplans/getProjectPlans");
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const importProjectPlan = async (data) => {
  try {
    return await api.post("projectplans/importProjectPlan", data);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};