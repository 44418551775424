import React, { useState, useContext } from 'react';
import {addProject} from '../../services/project_services';
import ProjectEditModal from '../../components/projects/ProjectEditModal';
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext";

const NewProject = ({activities, setActivities, students, categories, projects, setProjects, showAddProjectModal, setShowAddProjectModal }) => {

    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('literature');
    const [description, setDescription] = useState('');
    const [dateCompleted, setDateCompleted] = useState(null);
    const [studentsToInclude, setStudentsToInclude] = useState([]);
    const [categoriesToInclude, setCategoriesToInclude] = useState([]);
    const location = useLocation();
    const { country } = useContext(UserContext);

    const submitNewProject = (event) => {
        event.preventDefault();

        const data = {
            name: name,
            color: color,
            icon: icon,
            description: description,
            attachments: [],
            dateCompleted: dateCompleted,
            students: studentsToInclude, 
            categories: categoriesToInclude,
        }
        addProject(data).then((response) => {
            logAction("Created project", country, location.pathname, response.data.data); //Mixpanel logging
            setProjects([...projects, response.data.data]);
            document.getElementById("projectedit_form").reset()

        }).catch(error =>{
            console.log("An error occured during student submission:", error);
        });
        hideModal();
    }

    const hideModal = (event) => {
        setName('');
        setColor('');
        setIcon('literature');
        setDescription('');
        setDateCompleted(null);
        setStudentsToInclude([]);
        setCategoriesToInclude([]);
        setShowAddProjectModal(false);
    }

    return (
        <ProjectEditModal
            students={students} 
            categories={categories} 
            isOpen={showAddProjectModal} 
            submitFunction={submitNewProject} 
            cancelFunction={hideModal}
            name={name} 
            setName={setName} 
            color={color} 
            setColor={setColor} 
            icon={icon}
            setIcon={setIcon}
            description={description}
            setDescription={setDescription}
            dateCompleted={dateCompleted}
            setDateCompleted={setDateCompleted}
            studentsToInclude={studentsToInclude}
            setStudentsToInclude={setStudentsToInclude}
            categoriesToInclude={categoriesToInclude}
            setCategoriesToInclude={setCategoriesToInclude}
        />
    )
}

export default NewProject;