import React, { Fragment, useContext, useState } from "react";
import { Typography, CardContent, Card, CardHeader, CardActions, Dialog, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UserDetails from "../../components/common/UserDetails";
import UserContext from "../../UserContext";
import StyledButton from "../common/StyledButton";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        border: "none"
    },
    lrLine: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
}));

const UserDetailsSummary = () => {
    const classes = useStyles();
    const { firstName, lastName, email } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    const handleOpen = () => {
        setShowModal(true);
    };
    
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Fragment>
            <Card variant="outlined" className={classes.cardContainer}>
                <CardHeader title="My Details" />
                <CardContent>
                    <div className={classes.lrLine}>
                        <Typography>First Name:</Typography>
                        <Typography className={classes.value}>{firstName}</Typography>
                    </div>
                    <div className={classes.lrLine}>
                        <Typography>Last Name:</Typography>
                        <Typography className={classes.value}>{lastName}</Typography>
                    </div>
                    <div className={classes.lrLine}>
                        <Typography>Email:</Typography>
                        <Typography className={classes.value}>{email}</Typography>
                    </div>
                </CardContent>
                <CardActions>
                    <StyledButton onClick={handleOpen}>
                        Edit
                    </StyledButton>
                </CardActions>
                <Dialog open={showModal}>
                    <UserDetails 
                        showModal={showModal} 
                        handleClose={handleClose} 
                    />
                </Dialog>
            </Card>
            <Divider />
        </Fragment>
    )
};

  export default UserDetailsSummary;