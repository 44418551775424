import React, { Fragment, useState, useEffect, useContext } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Dialog, DialogActions, DialogContent, InputLabel, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from "@material-ui/core";
import PdfViewer from "./PdfViewer";
import Spinner from "./Spinner";
import ClearIcon from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAttachmentWithCache, uploadFile } from "../../services/attachment_services";
import { deleteSingleAttachment, getAttachmentLink } from "../../services/attachment_services";
import Dropzone from "react-dropzone";
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import StyledButton from "../common/StyledButton";
import { getThumbnails } from "../../containers/activities/GetAttachments";
import FileRejectionModal from "./FileRejectionModal";

const useStyles = makeStyles((theme) => ({
  label: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  deleteImgIcon: {
    color: theme.palette.common.white,
  },
  thumbnailImg: {
    cursor: "pointer",
  },
  thumbnailIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[400],
    fontSize: "3em",
    height: "100%",
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: theme.palette.grey[400],
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
    outline: "none",
    transition: "border .24s ease-in-out",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    textAlign: "center",
  },
}));

  const Attachments = ({attachments, setAttachments, location, setShowSpinner, allowAddActivity = false, optionalContext = {}, setNotificationDetails = null, setAttachmentUploaded = null }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { country } = useContext(UserContext);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pdfProps, setPdfProps] = useState({});
    const [uploading, setUploading] = useState(false);
    const [showFileRejections, setShowFileRejections] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [showAttachDeleteConfirm, setShowAttachDeleteConfirm] = useState(false);
    const [fileToDelete, setFileToDelete] = useState("");
    const [thumbnails, setThumbnails] = useState([]);
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    getThumbnails(attachments, setThumbnails);
    setUploading(false);
  }, [attachments]);

    useEffect(() => {
        if (uploading) {
            setShowSpinner(true);
        } else {
            setShowSpinner(false);
        }
    }, [uploading, setShowSpinner]);

    const fileDropzone = () => {
        let attachmentList = attachments === null ? [] : [...attachments];

    const handleUpload = async (acceptedFiles) => {
      acceptedFiles.map(async (file) => {
        var s3FileName = await uploadFile(file);
        var userFileName = file.name;
        var fileType = file.type;
        attachmentList.push({ userFileName, s3FileName, fileType });
        setAttachments([...attachmentList]);
        logAction("Uploaded attachment", country, location.pathname, { fileType: file.type, fileSize: file.size }); //Mixpanel logging
      });
      if (setAttachmentUploaded) {
        setAttachmentUploaded(true);
      }
    };

    const handleFileRejection = (files) => {
      const fileList = files.map((file) => {
        return file.file;
      });
      setRejectedFiles(fileList);
      setShowFileRejections(true);
    };

    return (
      <Fragment>
        <Dropzone
          maxSize={200000000}
          onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          onDropAccepted={() => {
            setUploading(true);
          }}
          onDropRejected={(files) => {
            handleFileRejection(files);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={classes.dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>
                Drag files here, or click to select files
                <br />
                (Maximum size: 200MB)
              </p>
            </div>
          )}
        </Dropzone>
        <Spinner open={uploading} />
        <FileRejectionModal 
          showFileRejections={showFileRejections} 
          setShowFileRejections={setShowFileRejections} 
          rejectedFiles={rejectedFiles} 
          title={"Upload Failure"}
          message={"The following files could not be uploaded..."}
          showFileSize={true}
        />
      </Fragment>
    );
  };

  const deleteAttachment = (s3FileName) => {
    logAction("Deleting attachment", country, location.pathname, { s3FileName: s3FileName }); //Mixpanel logging
    deleteSingleAttachment(s3FileName)
      .then(() => {
        setAttachments(attachments.filter((attachment) => attachment.s3FileName !== s3FileName));
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    setShowAttachDeleteConfirm(false);
  };

  const displayThumbnails = () => {
    const openThumbnail = (thumbnail) => {
      logAction("Opened attachment", country, location.pathname, { fileType: thumbnail.fileType }); //Mixpanel logging
      let attachmentWindow = window.open("", "_blank");
      attachmentWindow.document.write("Loading attachment...");
      getAttachmentLink({ fileName: thumbnail.s3FileName }).then((response) => {
        attachmentWindow.location.href = response.data.data.signedUrl;
      });
    };

    const openPdf = (thumbnail) => {
      logAction("Opening Pdf", country, location.pathname, { fileType: thumbnail.fileType }); //Mixpanel logging
      setShowSpinner(true);
      getAttachmentWithCache({ fileName: thumbnail.s3FileName }).then(async (pdfCachePromise) => {
        const pdfBlobResult = await pdfCachePromise;
        if (pdfBlobResult.success) {
          const pdfBlob = pdfBlobResult.pdfBlob;
          logAction("Opened Pdf from " + pdfBlobResult.method, country, location.pathname, { fileType: thumbnail.fileType, errorMessage: pdfBlobResult.errorMessage}); //Mixpanel logging
          setShowSpinner(false);
          setPdfProps({
            name: thumbnail.userFileName,
            file: pdfBlob,
            s3FileName: thumbnail.s3FileName,
            fileType: thumbnail.fileType,
          });
          setShowPdfViewer(true);
        } else {
          logAction("Failed to open Pdf", country, location.pathname, { //Mixpanel logging
            fileType: thumbnail.fileType,
            method: pdfBlobResult.method,
            errorMessage: pdfBlobResult.errorMessage
          }); 
          setShowSpinner(false);
        }
      });
    };

    const deleteConfirmModal = () => {
      return (
        <Dialog open={showAttachDeleteConfirm}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this attachment?</Typography>
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={() => {
                handleDeleteCancel();
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={() => {
                deleteAttachment(fileToDelete);
              }}
            >
              OK
            </StyledButton>
          </DialogActions>
        </Dialog>
      );
    };

    const handleDelete = (s3FileName) => {
      setFileToDelete(s3FileName);
      setShowAttachDeleteConfirm(true);
    };

    const handleDeleteCancel = () => {
      setFileToDelete("");
      setShowAttachDeleteConfirm(false);
    };

    const displayLink = (thumbnail) => {
      switch (thumbnail.fileType) {
        case "image/jpeg":
        case "image/png":
          return (
            <img
              src={thumbnail.signedUrl}
              alt={thumbnail.userFileName}
              className={classes.thumbnailImg}
              variant="rounded"
              onClick={() => {
                  openThumbnail(thumbnail);
              }}
            />
          );
        case "application/pdf":
          return (
            <div
              className={classes.thumbnailIcon}
              onClick={() => {
                  openPdf(thumbnail);
              }}
              >
              <FontAwesomeIcon icon="file-pdf" />
            </div>
          );
        default:
          return (
            <div
              className={classes.thumbnailIcon}
              onClick={() => {
                openThumbnail(thumbnail);
              }}
            >
              <FontAwesomeIcon icon="paperclip" />
            </div>
          );
      };
    };

    return (
      <div>
        <InputLabel className={classes.label}>Attachments</InputLabel>
        <ImageList rowHeight={160} cols={screenIsXs ? 2 : 4}>
          {thumbnails.map((thumbnail, i) => {
            if (thumbnail === undefined) {
              // user will be redirected to login; need to prevent error here when trying to display undefined thumnails
              return <div key={i}></div>;
            }
            return (
              <ImageListItem key={i} title={thumbnail.userFileName}>
                {displayLink(thumbnail)}
                <ImageListItemBar
                  title={thumbnail.userFileName}
                  actionIcon={
                    <IconButton onClick={() => handleDelete(thumbnail.s3FileName)} className={classes.deleteImgIcon}>
                      <ClearIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        <PdfViewer showPdfViewer={showPdfViewer} setShowPdfViewer={setShowPdfViewer} pdfProps={pdfProps} allowAddActivity={allowAddActivity} optionalContext={optionalContext} setNotificationDetails={setNotificationDetails} />
        {deleteConfirmModal()}
      </div>
    );
  };

  return (
    <Fragment>
      {displayThumbnails()}
      {fileDropzone()}
    </Fragment>
  );
};

export default Attachments;