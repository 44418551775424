import React, { useState, useContext } from 'react';
import ProjectsList from "../../components/projects/ProjectsList";
import NewProjectButton from "../../components/projects/NewProjectButton";
import ProjectImportButton from "../../components/projects/ProjectImportButton";
import StudentFilter from '../../components/common/StudentFilter';
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import PageList from '../../components/layout/PageList';
import IncludeCompleted from '../../components/projects/IncludeCompleted';
import DoubleFabWrapper from '../../components/common/DoubleFabWrapper';
import Spinner from '../../components/common/Spinner';

const Projects = ({ activities, setActivities, students, categories, projects, setProjects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {
    
    const [selectedStudent, setSelectedStudent] = useState(0);
    const [showCompleted, setShowCompleted] = useState(false);
    const [importing, setImporting] = useState(false);

    const { isReady } = useContext(SplitContext);
    const treatmentName = {
      projectImport: "project_import"
    };
    const treatment = useTreatments([treatmentName.projectImport]);

    return (
        <PageList navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            <StudentFilter 
                students={students} 
                selectedStudent={selectedStudent} 
                setSelectedStudent={setSelectedStudent} 
            />
            <IncludeCompleted showCompleted={showCompleted} setShowCompleted={setShowCompleted} />
            <ProjectsList 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects} 
                setProjects={setProjects} 
                selectedStudent={selectedStudent}
                showCompleted={showCompleted}
                setNotificationDetails={setNotificationDetails}
            />
            <DoubleFabWrapper>
                <NewProjectButton 
                    activities={activities} 
                    setActivities={setActivities} 
                    students={students} 
                    categories={categories} 
                    projects={projects} 
                    setProjects={setProjects} 
                />
                {/* FEATURE FLAG */}
                {isReady && treatment[treatmentName.projectImport].treatment === "on" &&
                    <ProjectImportButton
                        activities={activities}
                        setActivities={setActivities}
                        students={students} 
                        categories={categories} 
                        projects={projects} 
                        setProjects={setProjects} 
                        setImporting={setImporting}
                    />
                }
            </DoubleFabWrapper>
            <Spinner open={importing} />
        </PageList>
    )
}

export default Projects;
