import React, { Fragment } from 'react';
import { MenuItem, FormControl, Select, InputLabel, ListItemIcon, ListItemText, Chip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%",
    },
    categoryChip: {
      marginRight: theme.spacing(1),
    },
}));

const CategorySelector = ({ categories, categoriesToInclude, setCategoriesToInclude }) => {

    const classes = useStyles();

    let selectableCategoryList = [...categories];
    const handleChange = (event) => {
      setCategoriesToInclude(event.target.value);
    };

    const listItems = selectableCategoryList.map((category) => (
      <MenuItem key={category._id} value={category._id}>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.categoryIcon} icon={category.icon} style={{ color: category.color }} /> 
        </ListItemIcon>
        <ListItemText>
          {category.name}
        </ListItemText>
      </MenuItem>
    ));

    const getRenderValue = (values) => {
      return values.map((value, i) => {
        const categoryRecord = categories.find((category) => category._id === value)
        if (categoryRecord) {
          return (
            <Chip 
              key={i} 
              icon={<FontAwesomeIcon icon={categoryRecord.icon} style={{ color: categoryRecord.color }} size="lg" /> } 
              label={categoryRecord.name} 
              variant="outlined"
              className={classes.categoryChip}
            />
          )
        } else {
          return false;
        }
      })
    }

    return (
      <Fragment>
        <FormControl className={classes.fullWidth}>
          <InputLabel>Category(s)</InputLabel>
          <Select 
            name={"selectedCategories"} 
            value={categoriesToInclude} 
            renderValue={getRenderValue}
            onChange={handleChange} 
            multiple
          >
            {listItems}
          </Select>
        </FormControl>
      </Fragment>
    );
}

export default CategorySelector;