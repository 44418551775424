import React, { useState, useEffect } from 'react';
import ActivitiesList from '../../components/activities/ActivitiesList';
import NewActivityButton from '../../components/activities/NewActivityButton';
import StudentFilter from '../../components/common/StudentFilter';
import FilterList from '../../components/activities/FilterList';
import SearchBar from '../../components/activities/SearchBar';
import ExportToPdf from '../../components/activities/ExportToPdf';
import PageListDetail from '../../components/layout/PageListDetail';
import { getFilteredData } from '../activities/FilterData';
import { getAllActivities } from '../../services/activity_services';
import FilterPane from '../../components/layout/FilterPane';
import FilteredContentArea from '../../components/layout/FilteredContentArea';
import TopBar from '../../components/layout/TopBar';

const Schedule = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {

    const [selectedStudent, setSelectedStudent] = useState(0);
    const [filterIndex, setFilterIndex] = useState(0);
    const [filterText, setFilterText] = useState('');

    const filters = [
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 0,
                to: 0
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 1,
                to: 7
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 8,
                to: 30
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: 31,
                to: 999
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 0,
                from: -999,
                to: -1
            }
        }),
        getFilteredData(activities, {
            student: selectedStudent,
            completed: 0, 
            date: {
                mode: 2,
            }
        }),
    ]

    const filterLabels = [
        "Today",
        "Next 7 Days",
        "Next 30 Days",
        "Future",
        "Past",
        "Unscheduled",
    ];

    const filterListItems = filters.map((filter, i) => (
        {
            key: filterLabels[i],
            label: filterLabels[i],
            count: [filter.length],
            onClick: () => { setFilterIndex(i) }
        }
    ))

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    return (
        <PageListDetail navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            <FilterPane>
                <StudentFilter 
                    students={students} 
                    selectedStudent={selectedStudent} 
                    setSelectedStudent={setSelectedStudent} 
                />
                <FilterList filterListItems={filterListItems} setFilterIndex={setFilterIndex} />
            </FilterPane>
            <FilteredContentArea>
                <TopBar>
                    <SearchBar filterText={filterText} setFilterText={setFilterText} />
                    <ExportToPdf 
                        students={students} 
                        categories={categories}
                        projects={projects}
                        filterIndex={filterIndex}
                        filters={filters}
                        filterText={filterText}
                        sortMethod="date" 
                    />
                </TopBar>
                <ActivitiesList 
                    activities={activities} 
                    setActivities={setActivities} 
                    students={students} 
                    categories={categories} 
                    projects={projects}
                    filterIndex={filterIndex}
                    filters={filters}
                    filterText={filterText}
                    selectedStudent={selectedStudent}
                    sortMethod="date" 
                    setNotificationDetails={setNotificationDetails}
                />
            </FilteredContentArea>
            <NewActivityButton 
                setActivities={setActivities}
                activities={activities}
                students={students}
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
            />
        </PageListDetail>
    )
}

export default Schedule;
