import { Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
    }
}));

const IncludeCompleted = ({ showCompleted, setShowCompleted }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControlLabel 
                control={
                    <Switch 
                        checked={showCompleted}
                        onChange={() => {
                            showCompleted ? setShowCompleted(false) : setShowCompleted(true);
                        }}
                    />
                }
                label="Include Completed Projects"
            />
        </div>
    )
};

export default IncludeCompleted;