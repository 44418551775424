import React from 'react';
import Project from "./Project";
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "450px",
        width: "450px",
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
         [theme.breakpoints.down('md')]: {
            maxWidth: '360px',
        }
    },
}));

const ProjectsList = ({ activities, setActivities, students, categories, projects, setProjects, selectedStudent, showCompleted, setNotificationDetails }) => {

    const classes = useStyles();
    let data = projects;

    if (!showCompleted) {
        data = data.filter(project => !project.dateCompleted)
    }

    //Apply student filtering
    if (selectedStudent !== 0) {
        data = data.filter(project => {
            let studentMatch = false;
            project.students.forEach(s => {
                if (s === selectedStudent) {
                    studentMatch = true;
                }
            });
            return studentMatch;
        })
    }

    return (
        <div className={classes.root}>
            {projects.length === 0 && <Alert severity="info">Add a project</Alert>}
            <List>
                {data.map((project, i) => <Project activities={activities} setActivities={setActivities} students={students} categories={categories} key={i} data={project} projects={projects} setProjects={setProjects} setNotificationDetails={setNotificationDetails} />)}
            </List>
        </div>
    )
}

export default ProjectsList;