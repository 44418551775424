import React, { useState, useContext } from 'react';
import { Tabs, Tab, useMediaQuery, Chip } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import UserContext from "../../UserContext";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import EventIcon from '@material-ui/icons/Event';
import NotesIcon from '@material-ui/icons/Notes';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
    tabRoot: {
        maxWidth: "450px",
        width: "450px"
    },
    filterItem: {
        padding: theme.spacing(1),
        color: theme.palette.text.primary,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.divider,
        '&:hover': {
            backgroundColor: theme.palette.secondary.shade[50],
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
         [theme.breakpoints.down('md')]: {
            maxWidth: '360px',
        }, 
    },
    wrapper: {
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    wrapperFlex: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        [theme.breakpoints.down('md')]: {
            flexDirection: "row",
        }
    },
    label: {
        textAlign: "left",
    },
    counts: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            flexGrow: 1,
            paddingTop: 0,
        }
    },
    count: {
        marginLeft: theme.spacing(1),
    },
    selectedTab: {
        backgroundColor: theme.palette.secondary.shade[50],
        '&:hover': {
            backgroundColor: theme.palette.secondary.shade[100],
        },
    },
}));

const FilterList = ({filterListItems}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down('xs')); 
    const screenIsMd = useMediaQuery(theme.breakpoints.down('md')); 
    const location = useLocation();
    const { country } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        logAction("Applied filter", country, location.pathname, { value: filterListItems[newValue].label }); //Mixpanel logging
        setActiveTab(newValue);
    };

    const counts = (filterListItem) => {
        if (!screenIsXs && (!screenIsMd || filterListItem.count.length === 1)) {
            return (
                <div className={classes.counts}>
                    {filterListItem.count.map((countItem, i) => {
                        let icon = null
                        if (filterListItem.hasOwnProperty('countType')) {
                            switch (filterListItem.countType[i]) {
                                case "Scheduled":
                                    icon = <EventIcon />
                                    break
                                case "Unscheduled":
                                    icon = <NotesIcon />
                                    break
                                case "Completed":
                                    icon = <CheckIcon />
                                    break
                                default:
                                    break
                            }
                        }
                        return (
                            <Chip 
                                key={i}
                                className={classes.count}
                                size="small"
                                icon={icon}
                                label={countItem} 
                            />
                        )
                    })}
                </div>
            )
        }
    }

    const listItems = filterListItems.map((filterListItem, i) => (
        <Tab
            key={filterListItem.key}
            label={
                <div className={classes.wrapperFlex}>
                    <div className={classes.label}>
                        {filterListItem.label}
                    </div>
                    {counts(filterListItem)}
                </div>
            }
            className={classes.filterItem}
            classes={{ 
                root: classes.tabRoot,
                wrapper: classes.wrapper,
                selected: classes.selectedTab 
            }}
            onClick={() => { filterListItem.onClick() }} 
        />
    ));

    return (
            <Tabs 
                value={activeTab}
                onChange={handleTabChange}
                orientation={screenIsXs ? "horizontal" : "vertical"}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                {listItems}
            </Tabs>
    )
}

export default FilterList;