import React, { useState, useEffect, useMemo } from 'react';
import ActivitiesList from '../../components/activities/ActivitiesList';
import StudentFilter from '../../components/common/StudentFilter';
import FilterList from '../../components/activities/FilterList';
import SearchBar from '../../components/activities/SearchBar';
import ExportToPdf from '../../components/activities/ExportToPdf';
import NewActivityButton from '../../components/activities/NewActivityButton';
import { getFilters, getFilterListItems } from '../../containers/activities/CategoryFilters';
import { getAllActivities } from '../../services/activity_services';
import StatusTabs from '../../components/activities/StatusTabs';
import PageListDetail from '../../components/layout/PageListDetail';
import FilterPane from '../../components/layout/FilterPane';
import FilteredContentArea from '../../components/layout/FilteredContentArea';
import TopBar from '../../components/layout/TopBar';

const Progress = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails }) => {
    
    const [selectedStudent, setSelectedStudent] = useState(0);
    const [filterIndex, setFilterIndex] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [sortMethod, setSortMethod] = useState("date");

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    const progressFilters = useMemo(() => {
        return [
            { //Upcoming
                student: selectedStudent,
                completed: 0, 
                date: {
                    mode: 0,
                    from: 0,
                    to: 30
                }
            },
            { //Unscheduled
                student: selectedStudent,
                completed: 0, 
                date: {
                    mode: 2
                }
            },
            { //Completed
                student: selectedStudent,
                completed: 1, 
                date: {
                    mode: 0,
                    from: -90,
                    to: 1
                }
            }
        ]
    }, [selectedStudent])

    const [filterCriteria, setFilterCriteria] = useState(progressFilters[0]);
    const [filterCounts, setFilterCounts] = useState([]);

    useEffect(() => {
        switch (activeTab) {
            case 0:
                setFilterCriteria(progressFilters[0]);
                setSortMethod("date");
                break;
            case 1:
                setFilterCriteria(progressFilters[1]);
                setSortMethod("date");
                break;
            case 2:
                setFilterCriteria(progressFilters[2]);
                setSortMethod("dateCompletedDescending");
                break;
            default:
                break;
        }
    }, [activeTab, selectedStudent, progressFilters]);

    useEffect(() => {
        return (
        setFilterCounts([
            getFilters(activities, categories, progressFilters[0]).map((filter) => {
                return filter.length
            }),
            getFilters(activities, categories, progressFilters[1]).map((filter) => {
                return filter.length
            }),
            getFilters(activities, categories, progressFilters[2 ]).map((filter) => {
                return filter.length
            }),
        ])
        )
    }, [activities, categories, selectedStudent, progressFilters]);

    const filters = getFilters(activities, categories, filterCriteria);
    let filterListItems = getFilterListItems(activities, categories, setFilterIndex, filterCriteria);

    filterListItems= filterListItems.map((listItem, i) => {
        listItem.count = []
        listItem.countType = []
        filterCounts.forEach((count, j) => {
            listItem.count.push(count[i])
            switch (j) {
                case 0:
                    listItem.countType.push("Scheduled");
                    break;
                case 1: 
                    listItem.countType.push("Unscheduled");
                    break;
                case 2: 
                    listItem.countType.push("Completed");
                    break;
                default:
                    break;
            }
        })
        return listItem
    })

    return (
        <PageListDetail navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
            <FilterPane>
                <StudentFilter 
                    students={students} 
                    selectedStudent={selectedStudent} 
                    setSelectedStudent={setSelectedStudent} 
                />
                <FilterList filterListItems={filterListItems} />
            </FilterPane>
            <FilteredContentArea>
                <TopBar>
                    <SearchBar filterText={filterText} setFilterText={setFilterText} />
                    <ExportToPdf 
                        students={students} 
                        categories={categories}
                        projects={projects}
                        filterIndex={filterIndex}
                        filters={filters}
                        filterText={filterText}
                        sortMethod={sortMethod}
                    />
                </TopBar>
                <StatusTabs 
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab} 
                    filterIndex={filterIndex}
                    filterCounts={filterCounts}
                    tabLabels={["Upcoming", "Unscheduled", "Recently Completed"]}
                />
                <ActivitiesList 
                    activities={activities} 
                    setActivities={setActivities} 
                    students={students} 
                    categories={categories} 
                    projects={projects}
                    filterIndex={filterIndex}
                    filters={filters}
                    filterText={filterText}
                    selectedStudent={selectedStudent}
                    sortMethod={sortMethod}
                    setNotificationDetails={setNotificationDetails}
                    hideReorderMenu
                />
            </FilteredContentArea>
            <NewActivityButton 
                setActivities={setActivities}
                activities={activities}
                students={students}
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
            />
        </PageListDetail>
    )
}

export default Progress;
