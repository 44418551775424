import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import StudentSelector from '../../common/StudentSelector';
import ProjectDuplicateOptionStudentGrouping from '../projectDuplicateOptionStudentGrouping';

const StudentSelection = ({ students, studentsToInclude, setStudentsToInclude, optionParticipation, setOptionParticipation, studentGroups, setStudentGroups}) => {

    return (
        <Fragment>
            <Typography>Select the student(s) that will participate</Typography>
            <StudentSelector students={students} studentsToInclude={studentsToInclude} setStudentsToInclude={setStudentsToInclude} />
            <ProjectDuplicateOptionStudentGrouping 
                students={students}
                studentsToInclude={studentsToInclude}
                optionParticipation={optionParticipation}
                setOptionParticipation={setOptionParticipation}
                studentGroups={studentGroups}
                setStudentGroups={setStudentGroups}
            />
        </Fragment>
    )
}

export default StudentSelection;