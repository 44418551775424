import React, {useState, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import StyledButton from "../common/StyledButton";
import CategorySelectorList from "../common/CategorySelectorList";

const RoutineItemModal = ({showRoutineItemModal, selectedStudent, selectedDay, categoriesToInclude, setCategoriesToInclude, students, categories, cancelFunction, submitFunction}) => {

    const [studentName, setStudentName] = useState("");

    useEffect(() => {
        if (selectedStudent) {
            const studentRecord = students.find((student) => student._id === selectedStudent);
            setStudentName(studentRecord.name);
        }
    }, [students, selectedStudent]);

    const days = [
        "Mondays",
        "Tuedays",
        "Wednesdays",
        "Thursdays",
        "Fridays",
        "Saturdays",
        "Sundays"
    ]

    return (
        <Dialog open={showRoutineItemModal} onClose={cancelFunction}>
            <DialogTitle>
                {days[selectedDay]} for {studentName}
            </DialogTitle>
            <DialogContent>
                <form id="form" onSubmit={submitFunction} autoComplete="off">
                    <CategorySelectorList 
                        categories={categories} 
                        categoriesToInclude={categoriesToInclude} 
                        setCategoriesToInclude={setCategoriesToInclude} 
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton form="form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
};

export default RoutineItemModal;