import React, { useState, Fragment, useContext, useEffect } from 'react';
import { ListItem, Tooltip, Avatar, Typography, SvgIcon } from '@material-ui/core';
import ProjectModal from './ProjectModal'
import { makeStyles } from '@material-ui/core/styles';
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import { useLocation } from "react-router-dom";
import CustomProgressBar from '../common/CustomProgressBar';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getProjectIcons } from "../../containers/projects/ProjectIcons";
import ProjectPlanLogo from '../common/ProjectPlanLogo';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.divider,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
    },
    header: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
    },
    name: {
        marginLeft: theme.spacing(1),
    },
    status: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
    },
    progressCard: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
    },
    progressDetails: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    progressTextWrapper: {
        display: "flex",
        width: "100%",
    },
    progressText: {
        flexGrow: 1,
    },
    avatar: {
        height: "35px",
        width: "35px",
        marginRight: theme.spacing(1),
    },
    completedCheck: {
        color: theme.palette.complete.main,
    },
    icon: {
        marginRight: theme.spacing(1),
    }
  }));

const Project = ({activities, setActivities, students, categories, data, projects, setProjects, setNotificationDetails}) => {

    const classes = useStyles();
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [name, setName] = useState(data.name);
    const [color, setColor] = useState(data.color);
    const [icon, setIcon] = useState(data.icon);
    const [description, setDescription] = useState(data.description);
    const [attachments, setAttachments] = useState(data.attachments);
    const [projectId, setProjectId] = useState(data._id);
    const [dateCompleted, setDateCompleted] = useState(data.dateCompleted);
    const [studentsToInclude, setStudentsToInclude] = useState(data.students);
    const [categoriesToInclude, setCategoriesToInclude] = useState(data.categories);
    const location = useLocation();
    const { country } = useContext(UserContext);
    const projectIcons = getProjectIcons();

    useEffect(() => {
        setName(data.name);
        setColor(data.color);
        setIcon(data.icon);
        setDescription(data.description);
        setAttachments(data.attachments);
        setProjectId(data._id);
        setDateCompleted(data.dateCompleted);
        setStudentsToInclude(data.students);
        setCategoriesToInclude(data.categories);
    }, [data]);

    const onOpen = () => {
        logAction("Opened project", country, location.pathname, data);
        setName(data.name);
        setIcon(data.icon);
        setColor(data.color);
        setDescription(data.description);
        setAttachments(data.attachments);
        setProjectId(data._id);
        setDateCompleted(data.dateCompleted);
        setStudentsToInclude(data.students);
        setCategoriesToInclude(data.categories);
        setShowProjectModal(true);
    };

    const displayStudents = () => {
        let projectStudents = data.students.map((student_id, i) => { //Get student props
            const studentRecord = students.find((student) => student._id === student_id)
            if (studentRecord) {
                const studentProjectActivities = activities.filter((activity) => { //Also get students project activities
                    let studentMatch = false;
                    activity.students.forEach(s => {
                        if (s === student_id) {
                            studentMatch = true;
                        }
                    });
                    return studentMatch && activity.project === projectId;
                });
                const studentProjectActivitiesCompleted = studentProjectActivities.filter((activity) => activity.dateCompleted);
                studentRecord.activitiesCount = studentProjectActivities.length;
                studentRecord.activitiesCompletedCount = studentProjectActivitiesCompleted.length;
                studentRecord.percentComplete = studentProjectActivities.length ? (studentProjectActivitiesCompleted.length / studentProjectActivities.length) * 100 : 0;
              return studentRecord;
            } else {
              return false;
            }
        })
        projectStudents = projectStudents.sort((a, b) => { //Sort by _id
            if(a._id < b._id) { return -1; }
            if(a._id > b._id) { return 1; }
            return 0;
        })
        return (
            projectStudents.map((student, i) => {
                if (student) {
                    const secondaryColor = alpha(student.color, 0.4);
                    const studentInitial = student.name.substring(0, 1).toUpperCase();
                    return (
                        <div className={classes.progressCard} key={i}>
                            <Tooltip title={student.name}>
                                <Avatar className={classes.avatar} style={{ backgroundColor: `${student.color}` }}>{studentInitial}</Avatar>
                            </Tooltip>
                            <div className={classes.progressDetails}>
                                <CustomProgressBar 
                                    color={student.color}
                                    secondaryColor={secondaryColor}
                                    value={student.percentComplete} 
                                />
                                <div className={classes.progressTextWrapper}>
                                    <div className={classes.progressText}>
                                        <Typography color="textSecondary">{<strong>{student.activitiesCompletedCount}</strong>} of {<strong>{student.activitiesCount}</strong>} completed</Typography>
                                    </div>
                                    <Typography color="textSecondary">{Math.round(student.percentComplete)}%</Typography>
                                </div>
                            </div>
                        </div>
                    );
                  } else return false;
            })
        )
    }

    return (
        <Fragment>
            <ListItem className={classes.root} onClick={onOpen}>
                <div className={classes.header}>
                    {
                        data.logoUrl 
                        ? <ProjectPlanLogo name={data.name} logoUrl={data.logoUrl} />
                        : <SvgIcon 
                            className={classes.icon}
                            component={projectIcons[data.icon]} 
                            viewBox="0 0 128 128" 
                        />
                    }
                    <Typography className={classes.name}>{data.name}</Typography>
                    <div className={classes.status}>
                        {data.dateCompleted ? <CheckCircleIcon className={classes.completedCheck} /> : null}
                    </div>
                </div>
                {displayStudents()}
            </ListItem>
            <ProjectModal 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects}
                setProjects={setProjects}
                isOpen={showProjectModal}
                setShowProjectModal={setShowProjectModal}
                data={data}
                name={name}
                setName={setName}
                color={color}
                setColor={setColor}
                icon={icon}
                setIcon={setIcon}
                description={description}
                setDescription={setDescription}
                attachments={attachments}
                setAttachments={setAttachments}
                projectId={projectId}
                setProjectId={setProjectId}
                dateCompleted={dateCompleted}
                setDateCompleted={setDateCompleted}
                studentsToInclude={studentsToInclude}
                setStudentsToInclude={setStudentsToInclude}
                categoriesToInclude={categoriesToInclude}
                setCategoriesToInclude={setCategoriesToInclude}
                setNotificationDetails={setNotificationDetails}
            />
        </Fragment>
    )
}

export default Project;