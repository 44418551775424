import React, { Fragment, useState, useContext } from 'react';
import NewActivity from '../../containers/activities/NewActivity';
import { Fab, useMediaQuery, useTheme  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { logAction } from "../../log";
import UserContext from "../../UserContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    newButton: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        background: 'linear-gradient(135deg, rgba(219,90,107,1) 0%, rgba(245,143,132,1) 100%)', //#db5a6b Gradient colours from http://bootflat.github.io/color-picker-pink.html //#f58f84
        color: theme.palette.common.white,
        '&:hover': {
            background: 'linear-gradient(135deg, rgba(219,53,74,1) 0%, rgba(245,105,90,1) 100%)'
        },
    },
}));

const NewActivityButton = ({ activities, setActivities, students, categories, projects, setNotificationDetails }) => {

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { country } = useContext(UserContext);
    const screenIsMd = useMediaQuery(theme.breakpoints.down('md')); 
    const [showNewActivityModal, setShowActivityModal] = useState(false);

    return (
        <Fragment>
            <Fab 
                variant={screenIsMd ? "circular" : "extended"}
                className={classes.newButton}
                onClick={() => {
                    logAction("Clicked new activity button", country, location.pathname); //Mixpanel logging
                    setShowActivityModal(true);
                }}>
                <AddIcon />
                {screenIsMd ? null : "Add Activity"}
            </Fab>
            <NewActivity
                setActivities={setActivities}
                activities={activities}
                isOpen={showNewActivityModal}
                setShowActivityModal={setShowActivityModal}
                students={students}
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
            />
        </Fragment>
    )
};

export default NewActivityButton;