import React, {useState} from "react";
import { Typography } from '@material-ui/core';
import SimpleDialog from "../common/SimpleDialog";
import Logout from "../../containers/auth/Logout";

const LogoutModal = ({ showLogout, setShowLogout }) => {
  const [logoutTrigger, setLogoutTrigger] = useState(false);

  const hideModal = () => {
    setShowLogout(false);
  };

  const handleLogout = () => {
    hideModal();
    setLogoutTrigger(true);
  };

  return (
    <SimpleDialog 
          open={showLogout}
          onClose={hideModal}
          title="Logout"
          buttonPrimary={{
            onClick: () => {handleLogout()},
            label: "Logout"
          }}
          buttonSecondary={{
            onClick: () => {hideModal()},
            label: "Cancel"
          }}
    >
      <Typography>Are you sure you want to log out?</Typography>
      <Logout trigger={logoutTrigger} />
    </SimpleDialog>
  );
};

export default LogoutModal;