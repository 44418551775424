import React, { useState, useContext, Fragment } from 'react';
import {addCategory} from '../../services/category_services';
import CategoryModal from './CategoryModal';
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext";
import StyledButton from "../common/StyledButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    addButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }));

const NewCategory = ({categories, setCategories }) => {

    const classes = useStyles();
    const [name, setName] = useState('');
    const [color, setColor] = useState('#F58F84');
    const [icon, setIcon] = useState('book');
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const location = useLocation();
    const { country } = useContext(UserContext);

    const submitNewCategory = (event) => {
        event.preventDefault();

        const data = {
            name: name,
            color: color,
            icon: icon
        }
        addCategory(data).then((response) => {
            logAction("Created category", country, location.pathname, response.data.data); //Mixpanel logging
            setCategories([...categories, response.data.data]);
            document.getElementById("form").reset()

        }).catch(error =>{
            console.log("An error occured during student submission:", error);
        });
        hideModal();
    }

    const hideModal = (event) => {
        setName('');
        setColor('#F58F84');
        setIcon('book');
        setShowAddCategoryModal(false);
    }

    return (
        <Fragment>
            <div className={classes.addButton}>
                <StyledButton
                    onClick={() => {
                    setShowAddCategoryModal(true);
                    }}
                >
                    Add
                </StyledButton>
            </div>
            <CategoryModal isOpen={showAddCategoryModal} cancelFunction={hideModal} submitFunction={submitNewCategory} name={name} setName={setName} color={color} setColor={setColor} icon={icon} setIcon={setIcon} />
        </Fragment>
    )
}

export default NewCategory;