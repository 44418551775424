import React, { useEffect } from 'react';
import NewActivityButton from '../../components/activities/NewActivityButton';
import Calendar from '../../components/calendar/Calendar';
import { getAllActivities } from '../../services/activity_services';
import PageBase from '../../components/layout/PageBase';

const MyWeek = ({ activities, setActivities, students, categories, projects, navOpen, drawerWidth, bannerClearance, setNotificationDetails, routineItems, setRoutineItems }) => {

    useEffect(() => {
        getAllActivities()
            .then((response) => setActivities(response.data))
            .catch((error) => console.log(error))
    }, [setActivities]);

    return (
        <PageBase navOpen={navOpen} drawerWidth={drawerWidth}>
            <Calendar 
                activities={activities} 
                setActivities={setActivities} 
                students={students} 
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
                routineItems={routineItems}
                setRoutineItems={setRoutineItems}
                navOpen={navOpen}
                drawerWidth={drawerWidth}
                bannerClearance={bannerClearance}
            />
            <NewActivityButton 
                setActivities={setActivities}
                activities={activities}
                students={students}
                categories={categories} 
                projects={projects}
                setNotificationDetails={setNotificationDetails}
            />
        </PageBase>
    )
}

export default MyWeek;