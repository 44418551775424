import { logAction, logActivityAction } from "../../log";
import { updateActivity } from "../../services/activity_services";
import { submitNewActivity as addNewActivity } from "../../services/activity_services";
import { DateTime } from "luxon";

const submitActivity = ({activities, setActivities, activityId, setActivityId, details, studentsToInclude, categoriesToInclude, projectToInclude, activityDate, activityCompleteDate, attachments, attachmentLinks, comments, location, country, pendingSave, setPendingSave, setSaveError, setClosingModal, hideModal, closeOnSave}) => {
  if (pendingSave) {
    //This should only occur if the server has failed to respond to the new activity request before a subsequent auto save request
    logAction("Pending save", country, location.pathname); //Mixpanel logging
    return
  };

  if (activityId) {
    // update the saved activity
    setPendingSave(true);
    const submitData = {
      _id: activityId,
      textContent: details,
      date: activityDate,
      dateCompleted: activityCompleteDate,
      students: studentsToInclude,
      categories: categoriesToInclude,
      project: projectToInclude,
      attachments: attachments,
      attachmentLinks: attachmentLinks,
      comments: comments
    };
    
    updateActivity(submitData)
      .then((response) => {
        logActivityAction("Updated activity", country, location.pathname, submitData); //Mixpanel logging
        setPendingSave(false);
        setSaveError(false);
        if (closeOnSave) {
          //Only update local state when closing the modal
          let activityIndex = activities.findIndex((a) => a._id === submitData._id);
          let activityCopy = [...activities];
          activityCopy[activityIndex].textContent = details;
          activityCopy[activityIndex].date = activityDate;
          activityCopy[activityIndex].students = studentsToInclude;
          activityCopy[activityIndex].categories = categoriesToInclude;
          activityCopy[activityIndex].project = projectToInclude;
          activityCopy[activityIndex].dateCompleted = activityCompleteDate;
          activityCopy[activityIndex].attachments = attachments;
          activityCopy[activityIndex].attachmentLinks = attachmentLinks;
          activityCopy[activityIndex].comments = comments;
          setActivities([...activityCopy]);
          setClosingModal(false);
          const notificationData = {
            show: true, 
            date: activityDate, 
            project: projectToInclude,
            dateCompleted: activityCompleteDate,
          }
          hideModal(notificationData);
        }
      })
      .catch((error) => {
        setClosingModal(false);
        setPendingSave(false);
        setSaveError(true);
        logAction("Activity save error on update.", country, location.pathname, error); //Mixpanel logging
        return
      });
  } else {
    // else perform add
    setPendingSave(true);
    const data = {
      textContent: details,
      date: activityDate,
      dateCreated: DateTime.local().toUTC(),
      dateCompleted: activityCompleteDate,
      students: studentsToInclude,
      categories: categoriesToInclude,
      project: projectToInclude,
      attachments: attachments,
      attachmentLinks: attachmentLinks,
      comments: comments
    };

    addNewActivity(data)
      .then((response) => {
        logActivityAction("Created activity", country, location.pathname, response.data.data); //Mixpanel logging
        setActivities([...activities, response.data.data]); //Update local state in order to continue editing the activity
        setActivityId(response.data.data._id);
        setPendingSave(false);
        setSaveError(false);
        if (closeOnSave) {
          setClosingModal(false);
          const notificationData = {
            show: true, 
            date: activityDate, 
            project: projectToInclude,
            dateCompleted: activityCompleteDate,
          }
          hideModal(notificationData);
        }
      })
      .catch((error) => {
        setClosingModal(false);
        setPendingSave(false);
        setSaveError(true);
        logAction("Activity save error on creation.", country, location.pathname, error); //Mixpanel logging
        return
      });
  };
};

export default submitActivity;