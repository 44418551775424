import { createTheme } from '@material-ui/core'

const theme = createTheme({
    palette: {
        background: {
            default: "#ffffff",
        },
        primary: {
            main: '#651C32', //PANTONE 7421 C
            shade: {
                50: "#fde7ed", 
                100: "#fac4cf",
                200: "#e88a94",
                300: "#db5a6b", //Reference: http://bootflat.github.io/color-picker-pink.html, shades from https://material.io/inline-tools/color/
                400: "#e42649",
                500: "#e6002f",
                600: "#d8002f",
                700: "#c60029",
                800: "#b90022",
                900: "#aa0016",
            },
          },
        secondary: {
            main: '#f58f84', //Reference: http://bootflat.github.io/color-picker-pink.html, shades from https://material.io/inline-tools/color/
            shade: {
                50: "#ffe8e9", 
                75: "#fbd2ce", //Based on https://maketintsandshades.com/#f58f84
                100: "#ffc6c6",
                200: "#f58f84",
                300: "#e86456",
                400: "#eb442b",
                500: "#ea3600",
                600: "#dc2902",
                700: "#cb1c00",
                800: "#be1300",
                900: "#ae0100",
            }
        },
        complete: {
            main: '#16B578',
            shade: {
                50: "#e2f4eb", 
                100: "#b9e4ce",
                200: "#8ad2af",
                300: "#54c28f",
                400: "#16b578",
                500: "#00a861",
                600: "#009957",
                700: "#00874a",
                800: "#00763e",
                900: "#005729",
            }
        },
        grey: { //Reference: https://palx.jxnblk.com/ (based on #651C32)
            50: "#faf9f9",
            100: "#efeded",
            200: "#e3e0e1",
            300: "#d6d2d3",
            400: "#c8c2c4",
            500: "#b8b1b3",
            600: "#a69ea0",
            700: "#918689",
            800: "#76696d",
            900: "#4c3b40",
        },
        text: {
            primary: "#14060a",
            secondary: "#76696d",
        },
        action: {
            hover: "#ffe8e9",
        },
        error: {
            main: '#DB3635', //Reference: https://colors.eva.design/ (based on #DB5A6B)
          },
        warning: {
            main: '#CBC113',
        },
        info: {
            main: '#477DD8',
          },
        success: {
            main: '#16B578',
        },
    },
    overrides: {
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#f58f84',
                '&:hover': {
                    backgroundColor: '#eb442b',
                    border: '1px solid #eb442b'
                },
            },
            current: {
                color: '#14060a',
                border: '1px solid #f58f84'
            },
        }
    }
});

export default theme;