import React, { Fragment, useState, useContext } from 'react';
import { Fab, useMediaQuery, useTheme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import UserContext from "../../UserContext";
import ProjectImportModal from "./ProjectImportModal";

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: 'linear-gradient(135deg, rgba(219,90,107,1) 0%, rgba(245,143,132,1) 100%)', //#db5a6b Gradient colours from http://bootflat.github.io/color-picker-pink.html //#f58f84
        color: theme.palette.common.white,
        '&:hover': {
            background: 'linear-gradient(135deg, rgba(219,53,74,1) 0%, rgba(245,105,90,1) 100%)'
        },
    }
}));

const ProjectImportButton = ({activities, setActivities, students, categories, projects, setProjects, setImporting}) => {

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { country } = useContext(UserContext);
    const screenIsMd = useMediaQuery(theme.breakpoints.down('md')); 
    const [showProjectImportModal, setShowProjectImportModal] = useState(false);

    return (
        <Fragment>
            <Tooltip title="Import Project">
                <Fab 
                    variant={"circular"}
                    size={screenIsMd ? "large" : "medium"}
                    className={classes.button}
                    onClick={() => {
                        logAction("Clicked import project button", country, location.pathname);
                        setShowProjectImportModal(true);
                    }}>
                    <GetAppIcon />
                </Fab>
            </Tooltip>
            <ProjectImportModal activities={activities} setActivities={setActivities} students={students} categories={categories}  projects={projects} setProjects={setProjects} showProjectImportModal={showProjectImportModal} setShowProjectImportModal={setShowProjectImportModal} setImporting={setImporting} />
        </Fragment>
    )
}

export default ProjectImportButton;