import { makeStyles } from "@material-ui/core/styles";
import PageBase from "./PageBase";

const useStyles = makeStyles((theme) => ({
  root: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "500px",
      paddingLeft: "5px",
      paddingRight: "5px",
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: theme.palette.divider,
  },
}));

const Page = (props) => {
    const classes = useStyles();

    return (
      <PageBase navOpen={props.navOpen} drawerWidth={props.drawerWidth} bannerClearance={props.bannerClearance}>
        <div style={{height: props.bannerClearance}} />
        <div className={classes.root}>
          {props.children}
        </div>
      </PageBase>
    )
};

export default Page;