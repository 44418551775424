import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
    categoryHeading: {
        verticalAlign: "top"
    },
    categoryCell: {
        verticalAlign: "top",
        paddingBottom: 0
    },
    categoryTableCell: {
        border: "none",
        paddingTop: 0
    }
}));

const DetailsSummary = ({students, projectPlans, selectedProjectPlan, categoryMap, studentGroups}) => {

    const classes = useStyles();

    let projectPlanDetails = projectPlans.filter((projectPlan) => selectedProjectPlan === projectPlan._id)
    projectPlanDetails = projectPlanDetails[0];

    return (
        <Fragment>
            <Typography>Please confirm the details of the project to import</Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant='head'>Curriculum</TableCell>
                        <TableCell>{projectPlanDetails.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant='head'>Student(s)</TableCell>
                        <TableCell>
                            <ul>
                                {
                                    studentGroups.map((studentGroup, index) => {
                                        return (
                                            <li key={index}>
                                                {
                                                    studentGroup.map((s, i) => {
                                                        let matchingStudent = students.filter((student) => student._id === s)
                                                        matchingStudent = matchingStudent[0]
                                                        return <span key={i}>{`${matchingStudent.name}${i !== studentGroup.length - 1 ? ", " : ""} `}</span>
                                                    })
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
{/*                             {
                                selectedStudents.map((student, i) => {
                                    if (i === selectedStudents.length - 1) {
                                        return student.name
                                    } else {
                                        return student.name + ", "
                                    }
                                })
                            } */}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant='head' className={classes.categoryCell}>Category Mapping</TableCell>
                        <TableCell className={classes.categoryCell}>
                            <Table>
                                <TableBody>
                                    {categoryMap.map((categoryMapItem, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell className={classes.categoryTableCell}>{categoryMapItem.defaultCategory.name}</TableCell>
                                                <TableCell className={classes.categoryTableCell}><ChevronRightIcon /></TableCell>
                                                <TableCell className={classes.categoryTableCell}>{categoryMapItem.userCategory.name}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Fragment>
    )
}

export default DetailsSummary;