import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Activity from '../activity/Activity';
import { List, Typography } from '@material-ui/core';
import RoutineStatus from './RoutineStatus';

const useStyles = makeStyles(theme => ({
    root: {
        flexBasis: 0,
        minWidth: "150px",
        paddingTop: 0,
        borderStyle: "solid",
        borderColor: theme.palette.grey[200],
        borderRightWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
        },
    },
    header: {
        marginTop: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[400],
        width: "101%", //to overlap thin light grey border between columns
        height: theme.spacing(4),
    },
    headerWithTitle: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[400],
        paddingLeft: theme.spacing(1),
        width: "100%",
        height: theme.spacing(4),
    },
    columnBody: {
        //height: "100%",
        margin: theme.spacing(0.5),
    },
    columnBodyPast: {
        //flexBasis: 0,
        backgroundColor: theme.palette.grey[100]
    },
    columnBodyRoutine: {
        flexBasis: 0,
        borderStyle: "solid",
        borderColor: theme.palette.common.white,
        borderRightWidth: theme.spacing(0.5),
        borderLeftWidth: theme.spacing(0.5),
        borderTopWidth: theme.spacing(0.5),
        height: "100%",
    },
    alert: {
        width: "100%",
        marginLeft: theme.spacing(0.5)
    },
    dayHeader: {
        borderStyle: "solid",
        borderColor: theme.palette.common.white,
        borderWidth: theme.spacing(0.25),
        borderTopWidth: 0,
        borderBottomWidth: 0,
    },
    dayHeaderToday: {
        backgroundColor: theme.palette.secondary.shade[100]
    },
    dayHeaderPast: {
        backgroundColor: theme.palette.grey[300],
    },
    dayHeaderDay: {
        textAlign: "center",
    },
    dayHeaderDate: {
        textAlign: "center",
        fontSize: "2em"
    },
}));

const WeekViewColumn = ({group, activities, setActivities, students, categories, projects, date, dateDiff, filterText, selectedStudent, setNotificationDetails, studentsExpanded, setStudentsExpanded, routineItemsForDay, columnHeight}) => {

    const classes = useStyles();

    const toggleStudentExpand = (studentId) => {
        let studentsExpandedCopy = [...studentsExpanded];
        let hasBeenExpanded = studentsExpanded.filter((student) => studentId === student.id);
        hasBeenExpanded = hasBeenExpanded.length ? true : false;
        if (hasBeenExpanded) {
            studentsExpandedCopy = studentsExpandedCopy.map((student) => {
                if (student.id === studentId) {
                    const currentVal = student.expanded;
                    return {
                        id: student.id,
                        expanded: currentVal ? false : true
                    }
                } else {
                    return student
                }
            })
        } else {
            studentsExpandedCopy.push({
                id: studentId,
                expanded: true
            })
        }
        setStudentsExpanded(studentsExpandedCopy);
    }

    return (
        <List key={group.dateLabel} className={classes.root}>
            <div className={classes.dayHeader}>
                <div className={`${classes.dayHeader} ${!dateDiff && classes.dayHeaderToday} ${dateDiff < 0 && classes.dayHeaderPast}`}>
                    <div className={classes.dayHeaderDay}>{date.toLocaleString({weekday: "short"}).toUpperCase()}</div>
                    <div className={classes.dayHeaderDate}>{date.toLocaleString({day: "numeric"})}</div>
                </div>
            </div>
            <Fragment>
                { 
                    group.weekday === 1 
                    ? <div className={classes.headerWithTitle}><Typography variant="overline">Routine</Typography></div>
                    : <div className={classes.header} />
                }
                {
                    students.map((student, studentIndex) => {
                        let hasCategories = false;
                        let maxCategories = 0;
                        let studentRoutineItems = routineItemsForDay.filter((studentObj) => {
                            if (studentObj.studentId === student._id) {
                                hasCategories = studentObj.hasCategories;
                                maxCategories = studentObj.maxCategories;
                                return studentObj.routineItems;
                            } else {
                                return null;
                            }
                        });
                        if (studentRoutineItems.length) {
                            studentRoutineItems = studentRoutineItems[0].routineItems;
                        };

                        if (hasCategories && (selectedStudent === 0 || selectedStudent === student._id)) {
                            let routineStatusData = [];
                            group.activities.forEach((activity) => {
                                for (let s of activity.students) {
                                    if (s === student._id) {
                                        for (let category of activity.categories) {
                                            if (!routineStatusData[category]) {
                                                routineStatusData[category] = {
                                                    completed: 0,
                                                    scheduled: 0
                                                }
                                            }
                                            if (activity.dateCompleted) {
                                                routineStatusData[category].completed = routineStatusData[category].completed + 1
                                            } else {
                                                routineStatusData[category].scheduled = routineStatusData[category].scheduled + 1
                                            }
                                        }
                                    }
                                }
                            });

                            return (
                                <div key={student._id}>
                                    <div className={`${classes.columnBodyRoutine} ${dateDiff < 0 && classes.columnBodyPast}`}>
                                        <RoutineStatus 
                                            activities={activities}
                                            setActivities={setActivities}
                                            students={students}
                                            categories={categories}
                                            projects={projects}
                                            setNotificationDetails={setNotificationDetails}
                                            student={student} 
                                            routineItemsForDay={routineItemsForDay} 
                                            dateHeader={date}
                                            studentsExpanded={studentsExpanded}
                                            expandFunction={toggleStudentExpand}
                                            routineStatusData={routineStatusData}
                                            studentRoutineItems={studentRoutineItems}
                                            maxCategories={maxCategories}
                                        />
                                    </div>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Fragment>
            { 
                group.weekday === 1 
                ? <div className={classes.headerWithTitle}><Typography variant="overline">Schedule</Typography></div>
                : <div className={classes.header} />
            }
            <div className={`${classes.columnBody} ${dateDiff < 0 && classes.columnBodyPast}`} style={{height: columnHeight}}>
                {group.activities.map((activity, index) => {
                    return (
                        <Activity 
                            key={activity._id} 
                            students={students} 
                            categories={categories} 
                            projects={projects} 
                            data={activity} 
                            activities={activities} 
                            setActivities={setActivities} 
                            filterText={filterText}
                            selectedStudent={selectedStudent}
                            groupActivities={group.activities}
                            activityGroupIndex={index}
                            setNotificationDetails={setNotificationDetails}
                            narrow
                        />
                    )
                })}
            </div>
        </List>
    )
};

export default WeekViewColumn;