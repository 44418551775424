import React, { useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import StyledButton from "../common/StyledButton";
import { makeStyles } from "@material-ui/core/styles";
import UserContext from "../../UserContext";
import { updateShowTutorial } from "../../services/user_services";
import { logAction } from "../../log";
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column"
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginTop: theme.spacing(1),
      '&:not(:first-child)': {
        marginLeft: 0,
      }
    }
  }
}));

const Tutorial = ({showModalTutorial, setShowModalTutorial}) => {
  const classes = useStyles();
  const location = useLocation();
  const { country, setShowTutorial } = useContext(UserContext);
  let videoProgress = {};

  const postponeTutorial = () => {
    logAction("Postponed tutorial", country, location.pathname, videoProgress); //Mixpanel logging
    setShowModalTutorial(false);
  }

  const startTutorial = () => {
    window.open('https://www.learnfreely.app/onboarding-gettingstarted', '_blank');
    logAction("Started tutorial", country, location.pathname, videoProgress); //Mixpanel logging
    updateShowTutorial({value: false});
    setShowTutorial(false);
    setShowModalTutorial(false);
  }

  return (
    <Dialog open={showModalTutorial}>
      <DialogTitle>
        Welcome!
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography paragraph>
          Tiff explains how to get started with Freely
        </Typography>
        <ReactPlayer 
          url='https://video.wixstatic.com/video/b5c998_4f9c77229fff42fea63446032bc4bc40/1080p/mp4/file.mp4'
          height="100%"
          width="100%"
          controls={true}
          playing={true}
          muted={true}
          onProgress={
            (progress) => { videoProgress = progress }
          }
          onStart={
            () => { logAction("Introduction video started", country, location.pathname); } //Mixpanel logging
          }
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <StyledButton className={classes.button} onClick={() => postponeTutorial()}>I'll watch this later</StyledButton>
        <StyledButton className={classes.button} onClick={() => startTutorial()}>Let's go!</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default Tutorial;