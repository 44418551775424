import React from "react";
import CategoriesList from "../../components/categories/CategoriesList";
import NewCategoryButton from "../../components/categories/NewCategoryButton";
import PageSimple from "../../components/layout/PageSimple";

const Categories = ({ categories, setCategories, navOpen, drawerWidth, bannerClearance }) => {

  return (
    <PageSimple navOpen={navOpen} drawerWidth={drawerWidth} bannerClearance={bannerClearance}>
      <CategoriesList categories={categories} setCategories={setCategories} />
      <NewCategoryButton categories={categories} setCategories={setCategories} />
    </PageSimple>
  );
};

export default Categories;
