import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: "flex",
    },
  }));

const FilteredContentArea = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
};

export default FilteredContentArea;