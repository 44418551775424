import React from "react";

// set defaults for the context
const ActivityContext = React.createContext({
    activityId:'',
    setActivityId: () => {},
    details: '',
    setDetails: () => {},
    studentsToInclude: [],
    setStudentsToInclude: () => {},
    categoriesToInclude: [],
    setCategoriesToInclude: () => {},
    projectToInclude: '',
    setProjectToInclude: () => {},
    activityDate: null,
    setActivityDate: () => {},
    activityCompleteDate: null,
    setActivityCompleteDate: () => {},
    attachments: [],
    setAttachments: () => {},
    attachmentLinks: [],
    setAttachmentLinks: () => {},
    comments: [],
    setComments: () => {},
});

export default ActivityContext;