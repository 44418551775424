import React, { Fragment, useState, useContext } from "react";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SimpleDialog from "../common/SimpleDialog";
import { useLocation } from "react-router-dom";
import { reorderActivities } from "../../services/activity_services";
import { refreshActivities } from "../../services/data_refresh";
import { logAction, logActivityAction } from "../../log";
import UserContext from "../../UserContext";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    cardMoreButton: {
        padding: 0,
        color: theme.palette.grey[500]
    },
    listItemIconNarrow: {
        minWidth: 0,
        marginRight: theme.spacing(2)
    }
}));

const ReorderMenu = ({ activities, setActivities, filterText, selectedStudent, groupActivities, activityGroupIndex }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const location = useLocation();
    const { country } = useContext(UserContext);

    const disabledByFilter = selectedStudent !== 0 || filterText.length >= 2 ? true : false;

    const reorderMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    
    const reorderMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const hideErrorModal = (event) => {
        event.stopPropagation();
        setShowError(false);
    };

    const reorderActivity = (direction) => {
        const thisActivity = groupActivities[activityGroupIndex];
        let newGroupActivities = groupActivities;
        switch (direction) {
        case "top":
            newGroupActivities.splice(activityGroupIndex, 1);
            newGroupActivities.unshift(thisActivity);
            break;
        case "up":
            const activityAbove = groupActivities[activityGroupIndex - 1];
            newGroupActivities.splice(activityGroupIndex - 1, 2, thisActivity, activityAbove);
            break;
        case "down":
            const activityBelow = groupActivities[activityGroupIndex + 1];
            newGroupActivities.splice(activityGroupIndex, 2, activityBelow, thisActivity);
            break;
        case "bottom":
            newGroupActivities.splice(activityGroupIndex, 1);
            newGroupActivities.push(thisActivity);
            break;
        default:
            break;
        };
        for (let a of groupActivities) { //Rebuild activity array with new ordering
            let index = activities.findIndex((activity) => a._id === activity._id);
            setActivities(activities.splice(index, 1));
        };
        setActivities(activities.concat(newGroupActivities));
        reorderActivities(newGroupActivities)
        .then((response) => {
            logActivityAction("Reordered activity", country, location.pathname, thisActivity); //Mixpanel logging
        })
        .catch((error) => {
            logAction("Error while reordering activities", country, location.pathname, error); //Mixpanel logging
            if (error.response.status === 413) { //payload too large
                setErrorMessage("The list of activities you are trying to reorder is too large")
                setShowError(true);
            } else {
                setErrorMessage("An error occurred while trying to reorder activities")
                setShowError(true);
            };
            refreshActivities(setActivities);
        });
    }

    const getDisabledStatus = (direction) => {
        if (disabledByFilter) {
        return true;
        } else {
        switch (direction) {
            case "top":
            case "up":
                if (activityGroupIndex === 0) {
                return true;
                } else {
                return false;
                };
            case "down":
            case "bottom":
                if (activityGroupIndex + 1 === groupActivities.length) {
                return true;
                } else {
                return false;
                };
            default:
            break;
        };
        };
    };

    return (
        <Fragment>
            <IconButton className={classes.cardMoreButton} onClick={reorderMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            {Boolean(anchorEl) && //For performance reasons, don't render menu unless menu button is clicked
            <Menu 
                id="reorder-menu" 
                anchorEl={anchorEl} 
                keepMounted 
                open={Boolean(anchorEl)} 
                onClick={reorderMenuClose} 
                onClose={reorderMenuClose}
                getContentAnchorEl={null}
            >
                {disabledByFilter
                ? <MenuItem
                    dense
                    disabled
                >
                    Activities cannot be reordered while student or text filter is applied
                </MenuItem>
                : <div>
                    <MenuItem 
                    dense 
                    onClick={() => {reorderActivity("top")}}
                    disabled={getDisabledStatus("top")}
                    >
                    <ListItemIcon className={classes.listItemIconNarrow}>
                        <FontAwesomeIcon icon="angle-double-up" />
                    </ListItemIcon>
                    <ListItemText>
                        Top
                    </ListItemText>
                    </MenuItem>
                    <MenuItem 
                    dense 
                    onClick={() => {reorderActivity("up")}}
                    disabled={getDisabledStatus("up")}
                    >
                    <ListItemIcon className={classes.listItemIconNarrow}>
                        <FontAwesomeIcon icon="angle-up" />
                    </ListItemIcon>
                    <ListItemText>
                        Up
                    </ListItemText>
                    </MenuItem>
                    <MenuItem 
                    dense 
                    onClick={() => {reorderActivity("down")}}
                    disabled={getDisabledStatus("down")}
                    >
                    <ListItemIcon className={classes.listItemIconNarrow}>
                        <FontAwesomeIcon icon="angle-down" />
                    </ListItemIcon>
                    <ListItemText>
                        Down
                    </ListItemText>
                    </MenuItem>
                    <MenuItem 
                    dense 
                    onClick={() => {reorderActivity("bottom")}}
                    disabled={getDisabledStatus("bottom")}
                    >
                    <ListItemIcon className={classes.listItemIconNarrow}>
                        <FontAwesomeIcon icon="angle-double-down" />
                    </ListItemIcon>
                    <ListItemText>
                        Bottom
                    </ListItemText>
                    </MenuItem>
                </div>
                }
            </Menu>
            }
            <SimpleDialog 
                open={showError}
                onClose={hideErrorModal}
                title="Error"
                buttonSecondary={{
                    onClick: (event) => {hideErrorModal(event)},
                    label: "OK"
                }}
            >
                <Typography>{errorMessage}</Typography>
            </SimpleDialog>
        </Fragment>
    )
};

export default ReorderMenu;