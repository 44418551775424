import React, { useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {TextField } from "@material-ui/core";
import UserContext from "../../UserContext";
import StyledButton from "../common/StyledButton";
import { logAction } from "../../log";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  leftButton: {
    marginRight: theme.spacing(1)
  },
  buttonHidden: {
    visibility: "hidden",
  }
}));

const AddComment = ({comments, setComments, location}) => {
    const classes = useStyles();
    const { country, userId } = useContext(UserContext);
    const [commentText, setCommentText] = useState('');

    const onCommentTextChange = (e) => {
      setCommentText(e.target.value)
    }
  
    const onCommentCancel = () => {
      setCommentText('')
    }

    const addComment = () => {
      const newComment = {
        userId: userId,
        commentType: "",
        dateCreated: DateTime.local().toUTC(),
        text: commentText
      }
      const commentsTemp = comments;
      setComments([...commentsTemp, newComment]);
      setCommentText('');
      logAction("Added activity comment", country, location.pathname, newComment); //Mixpanel logging
    }

    return (
      <Fragment>
        <TextField 
          variant="outlined" 
          margin="dense" 
          placeholder="Add a comment..." 
          value={commentText} 
          onChange={onCommentTextChange}
          multiline
          fullWidth
        />
        <StyledButton 
          className={`${classes.leftButton} ${!commentText ? classes.buttonHidden : ""}`}
          onClick={onCommentCancel}
        >
          Cancel
        </StyledButton>
        <StyledButton 
          className={!commentText ? classes.buttonHidden : ""} 
          disabled={!commentText}
          onClick={addComment}
        >
          Save
        </StyledButton>
      </Fragment>
    )
};

export default AddComment;