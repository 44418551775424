import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import StyledButton from "../common/StyledButton";

const FileRejectionModal = ({ showFileRejections, setShowFileRejections, rejectedFiles, title, message, showFileSize = true }) => {

    return (
        <Dialog open={showFileRejections}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography paragraph>{message}</Typography>
            <ul>
              {rejectedFiles.map((file, i) => {
                return (
                  <li key={i}>
                    <Typography>
                      {file.name} {showFileSize ? "(" + Math.round(file.size / 1000000) + " MB)" : null}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={() => {
                setShowFileRejections(false);
              }}
            >
              OK
            </StyledButton>
          </DialogActions>
        </Dialog>
      );
}

export default FileRejectionModal;