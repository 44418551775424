import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderRightColor: theme.palette.divider,
        [theme.breakpoints.down('xs')]: {
            borderRight: "none",
            width: "100%",
        },
    }
  }));

const FilterPane = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
};

export default FilterPane;