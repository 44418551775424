import axios from "axios";
import api from "../config/api";

export const uploadFile = async (file) => {
  var s3FileName = null;
  try {
    await api
      .post("attachments/sign_s3_post", {
        fileName: file.name,
        fileType: file.type,
      })
      .then(async (response) => {
        var returnData = response.data.data.returnData;
        s3FileName = returnData.fileName;

        const formData = new FormData();
        formData.append("Content-type", file.type);
        Object.entries(returnData.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", file);

        await axios.post(returnData.url, formData);
      });
    return s3FileName;
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const getAttachmentLink = async (s3FileName) => {
  try {
    return await api.post("attachments/sign_s3_get", s3FileName);
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

async function retrieveBlob(db, s3FileName) {
  let pdfBlobResult = {
    success: false,
    method: null,
    pdfBlob: undefined,
    errorMessage: null
  }
  try {
    // get from db if it exists, otherwise cache it in db and return
    var tx = db.transaction("attachments", "readwrite");
    var objectStore = tx.objectStore("attachments");
    var objectStoreRequest = objectStore.get(s3FileName.fileName);
    var pdfBlob;
    
    var getFileFromDb =  new Promise((resolve, reject) => {
      objectStoreRequest.onsuccess = async function (event) {
        pdfBlob = event.target.result;
        resolve(pdfBlob);
      }
    });

    pdfBlob = await getFileFromDb;

    if (pdfBlob) {
      pdfBlobResult.success = true;
      pdfBlobResult.method = "local";
      pdfBlobResult.pdfBlob = pdfBlob.file;
      return pdfBlobResult;
    } else {
      // get the S3 url for the file
      pdfBlobResult.method = "local via server";
      const getAttachmentResponse = await getAttachmentLink(s3FileName);
      if (getAttachmentResponse.data.success) { //if file exists in S3
        const fileUrl = getAttachmentResponse.data.data.signedUrl;
        try {
          // get the pdf file
          const blobResponse = await fetch(fileUrl);
          const pdfBlob = await blobResponse.blob();
          
          // cache it in the DB
          tx = db.transaction(["attachments"], "readwrite");
          objectStore = tx.objectStore("attachments")
          const newItem = [
            { id: s3FileName.fileName, 
              file: pdfBlob 
            },
          ];
          objectStoreRequest = objectStore.add(newItem[0]);
          objectStoreRequest.onerror = function(event) {
            console.log("Error adding attachment to database.");
          };
          objectStoreRequest.onsuccess = function(event) {
            console.log("Attachment added to database.");
          };
          
          pdfBlobResult.success = true;
          pdfBlobResult.pdfBlob = pdfBlob;
          return pdfBlobResult;
        } catch (error) { //if caching fails, return the S3 url instead
          pdfBlobResult.success = true;
          pdfBlobResult.method = "server directly";
          pdfBlobResult.pdfBlob = fileUrl;
          pdfBlobResult.errorMessage = error;
          return pdfBlobResult;
        }
      } else { //if file doesn't exist in S3
        pdfBlobResult.errorMessage = getAttachmentResponse.data.message.code;
        return pdfBlobResult;
      }
    }
  } catch (error) {
    pdfBlobResult.errorMessage = error;
    return pdfBlobResult;
  }
}

export const getAttachmentWithCache = async (s3FileName) => {
  // open the IndexedDB store and then call function to store/retrieve the blob
  const dbOpenRequest = indexedDB.open("freelyAttachmentCache", 1);
  let db;
  let pdfBlobResult;

  dbOpenRequest.onupgradeneeded = (event) => {
    db = event.target.result; 
    db.createObjectStore("attachments", {keyPath: "id"});
  }

  var dbPromise =  new Promise((resolve, reject) => {
    dbOpenRequest.onsuccess = async (event) => {
      db = dbOpenRequest.result;
      pdfBlobResult = await retrieveBlob(db, s3FileName);
      resolve(pdfBlobResult); 
    }
  });
  
  return dbPromise;
};

export const deleteSingleAttachment = async (s3FileName) => {
  console.log("Deleting single attachment");
  try {
    var response = await api.post("attachments/delete_single_attachment", { fileName: s3FileName });
    if (response.data.success !== true) {
      console.log(response.data.message);
      throw response.data.message;
    }
    return response;
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};

export const deleteMultipleAttachments = async (s3FileNameArray) => {
  console.log("Deleting multiple attachments");
  try {
    var response = await api.post("attachments/delete_multiple_attachments", { s3FileNameArray });
    if (response.data.success !== true) {
      console.log(response.data.message);
      throw response.data.message;
    }
    return response;
  } catch (error) {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        window.location.href = "/API/auth/login";
      }
    }
    console.log(error);
    throw error;
  }
};
