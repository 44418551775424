import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel } from '@material-ui/core';
import StyledButton from '../common/StyledButton';
import { CirclePicker } from 'react-color';

const useStyles = makeStyles(theme => ({
    nameLabel: {
        width: "100%",
    },
    colorLabel: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    }
}));

const StudentModal = ({ isOpen, cancelFunction, submitFunction, name, setName, color, setColor }) => {

    const classes = useStyles();

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onColorChange = (color) => {
        setColor(color.hex);
    }

    const colorList = [ //Reference: https://palx.jxnblk.com/ (based on #ca253c i.e. #CA4558 with increased saturation)
        "#d96375",
        "#d98e65",
        "#cbb32a",
        "#a3d34b",
        "#78da66",
        "#67da8f",
        "#5cd7c5",
        "#7abbdf",
        "#8f9ce4",
        "#ae91e4",
        "#d282e1",
        "#e080be"
    ]

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Student</DialogTitle>
            <DialogContent>
                <form id="form" onSubmit={submitFunction} autoComplete="off">
                    <TextField name="name" label="Name" className={classes.nameLabel} value={name} onChange={onNameChange} required/>
                    <InputLabel className={classes.colorLabel}>Colour</InputLabel>
                    <CirclePicker color={color} colors={colorList} onChangeComplete={onColorChange}/>
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={cancelFunction}>
                    Cancel
                </StyledButton>
                <StyledButton form="form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}

export default StudentModal;