import React, { useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import UserContext from "../../UserContext";
import ActivityContext from "./ActivityContext";
import ActivityModal from "../../components/activity/ActivityModal";

const EditActivity = ({ activities, setActivities, isOpen, setShowActivityModal, students, categories, projects, deleteFunction, setNotificationDetails }) => {
  const location = useLocation();
  const { country } = useContext(UserContext);
  const { activityDate } = useContext(ActivityContext);
  const oldDate = useRef("");

  useEffect(() => {
    if (oldDate.current === "") {
      oldDate.current = activityDate;
    }
  });
  

  const hideModal = (notificationData = {}) => {
    setShowActivityModal(false);
    if (!notificationData.dateCompleted && notificationData.date !== oldDate.current) { //if date has changed
      oldDate.current = notificationData.date;
      let message = "";
      if (notificationData.date) { //if there is a date set
        const d = DateTime.fromISO(notificationData.date);
        const locale = country === "US" ? "en-us" : "en-au";
        const formattedDate = d.toLocaleString({ weekday: "short", month: "short", day: "numeric", locale: locale });
        const today = DateTime.local().startOf('day');
        const difference = d.diff(today, "days").values.days;
        switch (difference) {
          case 0:
            message = "Activity is now scheduled for today";
            break;
          case 1: 
            message = "Activity is now scheduled for tomorrow";
            break;
          default:
            message = `Activity is now scheduled for ${formattedDate}`
        }
      } else { //if the date was cleared
        message = "Activity is now unscheduled";
      }
      setNotificationDetails({
        key: Date.now(),
        icon: "calendar",
        title: "Date changed",
        message: message
      });
    }
  };

  return (
    <ActivityModal activities={activities} setActivities={setActivities} isOpen={isOpen} deleteFunction={deleteFunction} deleteEnabled={true} students={students} categories={categories} projects={projects} location={location} hideModal={hideModal} setNotificationDetails={setNotificationDetails} />
  );
};

export default EditActivity;
