import React from 'react';
import { Button } from '@material-ui/core';

const StyledButton = (props) => {
    return (
        <Button variant="outlined" onClick={props.onClick} form={props.form} type={props.type} disabled={props.disabled} startIcon={props.startIcon} className={props.className} size={props.size}>
            {props.children}
        </Button>
    )
}

export default StyledButton;